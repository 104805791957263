import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'
import ReplyTwoToneIcon from '@mui/icons-material/ReplyTwoTone'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { Image } from 'mui-image'
import { MouseEventHandler, useState } from 'react'
import useWindowDimensions from '../../components/WindowDimesion'
import { snackActions } from '../../SnackbarUtilsConfigurator'
import { useDeletePostMutation } from './immaginiAPI'
import { ImmaginiAppBar } from './ImmaginiAppBar'
import { ImmaginiCamera } from './ImmaginiCamera'
import { ImmaginiList } from './ImmaginiList'
import { ImmagineRequest, TIPO_IMAGE } from './immaginiSlice'

export interface ImmaginiProps {
    cancel?: MouseEventHandler<HTMLButtonElement>
    onPreviewClick?: (imageRequest: ImmagineRequest | null) => void
    TIPO: TIPO_IMAGE
    id: string
    DOC_ID: string
}

export function Immagini({ TIPO, id, DOC_ID, cancel }: ImmaginiProps) {
    const [preview, setPreview] = useState<ImmagineRequest | null>(null)

    const { height, width } = useWindowDimensions()

    const [idDelete, setIdDelete] = useState<ImmagineRequest | null>(null)

    const [deleteImage, { isLoading: isLoadingDelete, error: errorDelete }] = useDeletePostMutation()

    return (
        <Dialog fullScreen open={true}>
            <ImmaginiAppBar TIPO={TIPO} id={id} DOC_ID={DOC_ID} cancel={cancel} />
            {preview ? (
                <>
                    <Dialog maxWidth="xs" open={idDelete != null}>
                        <DialogTitle>Confermi?</DialogTitle>
                        <DialogContent dividers>{`Premi Si per confermare`}</DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setIdDelete(null)
                                }}
                            >
                                No
                            </Button>
                            <Button
                                onClick={async () => {
                                    try {
                                        const resp = await deleteImage(idDelete!).unwrap()
                                        setIdDelete(null)
                                        setPreview(null)
                                        snackActions.success('Data deleted')
                                    } catch (error: any) {
                                        console.log(error)
                                    }
                                }}
                            >
                                Si
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <div style={{ width: '100%', height: '75%', textAlign: 'center', zIndex: 4, marginTop: '3%' }}>
                        <Image
                            duration={0.3}
                            style={{}}
                            fit="scale-down"
                            src={`${process.env.REACT_APP_ENDPOINT}/image/imageBin?id=${
                                preview?.id
                            }&width=0&height=${Math.floor(height)}&TIPO=${TIPO}&HASH=${preview?.HASH}`}
                            alt={preview.id}
                        />
                        <Button
                            onClick={() => setIdDelete(preview)}
                            startIcon={<DeleteForeverTwoToneIcon fontSize="inherit" />}
                            variant="contained"
                            style={{ position: 'relative', bottom: '12%' }}
                        >
                            ELIMINA
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setPreview(null)
                            }}
                            style={{ position: 'relative', bottom: '12%', left: '10%' }}
                        >
                            CHIUDI
                        </Button>
                    </div>
                </>
            ) : (
                <ImmaginiCamera TIPO={TIPO} id={id} DOC_ID={DOC_ID} />
            )}
            <ImmaginiList
                TIPO={TIPO}
                id={id}
                DOC_ID={DOC_ID}
                onPreviewClick={(immagineRequest: ImmagineRequest | null) => setPreview(immagineRequest)}
            />
        </Dialog>
    )
}
