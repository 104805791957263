import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { BrowserMultiFormatReader, IScannerControls } from '@zxing/browser'
import { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectCameraImmaginiDefault, setCameraDefault } from './immaginiSlice'

interface Props {
    cb(item: string): void
}

export const NO_CAMERA: MediaDeviceInfo = {
    label: 'Nessuna fotocamera',
    deviceId: 'noCamera',
    groupId: 'noCamera',
    kind: 'videoinput',
    toJSON: function () {
        return { ...this }
    },
}

export function ImmaginiVideoSelectDefault({ cb }: Props) {
    //   https://codesandbox.io/s/scan-barcode-forked-7r265t?file=/src/App.tsx:351-356
    const dispatch = useAppDispatch()
    const cameraDefault = useAppSelector(selectCameraImmaginiDefault)

    const video = useRef<any>()

    const controlsRef = useRef<IScannerControls | null>(null)

    console.log('ZXing code reader initialized')

    const [deviceSelected, setDeviceSelected] = useState<MediaDeviceInfo | null>(cameraDefault)

    const [devices, setDevices] = useState<Array<MediaDeviceInfo>>([NO_CAMERA])

    useEffect(() => {
        if (!navigator.mediaDevices?.enumerateDevices) {
            console.log('enumerateDevices() not supported.')
        } else {
            navigator.mediaDevices
                ?.enumerateDevices()
                .then(function (devices) {
                    const validDevices = devices.filter((it) => it.kind === 'videoinput').map((it) => it)

                    setDevices((vd) => [NO_CAMERA, ...validDevices])
                })
                .catch(function (err) {
                    console.log(err.name + ': ' + err.message)
                })
        }
    }, [])

    useEffect(() => {
        async function init() {
            if (deviceSelected?.deviceId != null) {
                const codeReader = new BrowserMultiFormatReader()
                if (deviceSelected.deviceId === NO_CAMERA.deviceId) {
                    controlsRef.current?.stop()
                } else {
                    controlsRef.current = await codeReader.decodeFromVideoDevice(
                        deviceSelected.deviceId,
                        video.current,
                        function (result, error) {
                            if (typeof result !== 'undefined') {
                                cb(result.getText())
                            }
                        }
                    )
                }

                dispatch(setCameraDefault(deviceSelected))
            }
        }
        init()

        return function cleanup() {
            console.log('clean')
            controlsRef.current?.stop()
        }
    }, [deviceSelected])

    return (
        <>
            <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ mt: 1 }}>
                {deviceSelected !== null && devices !== null && devices.length > 0 && (
                    <BarcodeVideoSelector
                        callback={(dev) =>
                            setDeviceSelected((prevDev: any) => {
                                return dev
                            })
                        }
                        devices={devices}
                        deviceSelected={deviceSelected}
                    />
                )}
                {deviceSelected !== null && deviceSelected.deviceId != NO_CAMERA.deviceId && (
                    <video style={{ width: '300px', height: '300px' }} ref={video}></video>
                )}
            </Grid>
        </>
    )
}

interface PropsSelector {
    callback(item: MediaDeviceInfo): void
    devices: Array<MediaDeviceInfo>
    deviceSelected: MediaDeviceInfo
}

export const BarcodeVideoSelector = ({ callback, devices, deviceSelected }: PropsSelector) => {
    return (
        <FormControl fullWidth>
            <InputLabel id="fotocamera-label">Fotocamera</InputLabel>
            <Select
                labelId="fotocamera-label"
                value={deviceSelected.deviceId}
                onChange={(event: any) => {
                    const key = event.target.value as string
                    const newValue = devices.find((device) => device.deviceId === key)
                    callback(newValue!)
                }}
                fullWidth
                label="Fotocamera"
            >
                {devices.map((device) => (
                    <MenuItem key={device.deviceId} value={device.deviceId}>
                        {device.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
