import { Avatar, Badge, Paper, Stack, styled, Typography } from '@mui/material'
import { Image } from 'mui-image'
import { useAppSelector } from '../../app/hooks'
import { ImmaginiProps } from './Immagini'
import { useImmaginiQuery } from './immaginiAPI'
import { ImmagineRequest, selectImages } from './immaginiSlice'

const PREVIEW_SIZE = 80

export function ImmaginiList({ id, DOC_ID, TIPO, onPreviewClick }: ImmaginiProps) {
    const { data, error, isFetching } = useImmaginiQuery({ id, TIPO })

    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 20,
        height: 20,
        border: `2px solid ${theme.palette.background.paper}`,
        fontSize: 10,
        paddingTop: 3,
    }))

    const images: ImmagineRequest[] = useAppSelector(selectImages)

    return (
        <>
            <Paper
                elevation={3}
                square
                style={{
                    overflow: 'auto',
                    paddingLeft: '4px',
                    paddingBottom: '4px',
                    bottom: '0px',
                    width: '100%',
                    height: 'auto',
                    position: 'fixed',
                }}
            >
                <Stack direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={1}>
                    {images.map((row: ImmagineRequest) => {
                        const url = `${process.env.REACT_APP_ENDPOINT}/image/imageBin?id=${row.id}&width=${PREVIEW_SIZE}&height=${PREVIEW_SIZE}&TIPO=${TIPO}&HASH=${row.HASH}`
                        return (
                            <Badge
                                style={{ marginTop: 10 }}
                                key={row.id}
                                overlap="rectangular"
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                badgeContent={<SmallAvatar>{row.PROGR}</SmallAvatar>}
                            >
                                <div
                                    onClick={() => {
                                        if (onPreviewClick) onPreviewClick(row)
                                    }}
                                >
                                    <Image duration={0.3} fit="cover" src={url} />
                                </div>
                            </Badge>
                        )
                    })}
                    {isFetching ? (
                        <Typography variant="subtitle1">Caricamente immagini in corso...</Typography>
                    ) : (
                        images.length === 0 && <Typography variant="subtitle1">Nessuna immagine caricata</Typography>
                    )}
                </Stack>
            </Paper>
        </>
    )
}
