import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { ColliConfezObj, ColliConfezObjBarcode } from '../colliConfez/colliConfezSlice'
import { TipiCodArt } from '../impostazioni/impostazioniSlice'

export interface ColliConfezRighePaginationFilterRequest {
    gridSordModel?: GridSortModel
    gridFilterModel?: GridFilterModel
    page: number
    pageSize: number

    row?: ColliConfezObj | undefined

    tipiCodArtInterno: TipiCodArt
}

export interface ColliConfezRigheObj {
    // COLLI_CONFEZ_RIGHE
    AZIENDA_ID?: string
    PROGR_COLLO_ID: string
    PROGR_COLLO_NUM_RIGA_ID: string
    NUM_RIGA: string
    STAZIONE?: string
    DOC_ID?: string
    DOC_RIGA_ID?: string
    COD_ART?: string
    COD_VAR?: string
    COD_ART_VAR?: string
    NUM_UM?: string
    UM?: string
    UM_BASE?: string
    QUANT_RIGA?: string
    QUANT_UM_BASE?: string
    UM_CONFEZIONE?: string
    NUMERO_CONFEZIONI?: string
    CONT_1_CONFEZIONE?: string
    COD_LOT?: string
    MATRICOLA?: string
    SOLO_MULTIPLI_CONFEZIONE?: string
    FLAG_CONVERS_EDITABILE?: string
    CONVERS_UM_BASE?: string
    DOCUM_RIGA_ID?: string
    DOC_TABLE?: string
    DOC_EVAS_DOC_ID?: string
    DOC_EVAS_DOC_RIGA_ID?: string
    MT_TRASVERSALI?: string
    COD_VAR_1?: string
    COD_VAR_2?: string
    COD_VAR_3?: string
    DRI_CC?: string
    DES_IMP_ART?: string
    ITEM?: string
    AREA_DECK?: string
    SOLO_DECK?: string

    COD_SECONDARIO_ART?: string
    DESCRIZIONE?: string

    ACI_COD_SECONDARIO_ART?: string
    ACI_DESCRIZIONE?: string
}

export interface ColliConfezRigheObjInsert {
    colliConfez: ColliConfezObj
    dataBarcode: ColliConfezObjBarcode
}

export interface ColliConfezRigheObjUpdate {
    newRow: ColliConfezRigheObj
    oldRow: ColliConfezRigheObj
    colliConfezObjBarcode: ColliConfezObjBarcode
}

export interface ValoriReorder {
    id: number
    id_ref: number

    oldIndex: number
    targetIndex: number
}

export interface ColliConfezRigheResponse {
    data: Array<ColliConfezRigheObj> | []
    dataBarcode: Array<ColliConfezRigheObj> | []
    message?: string | null
    rowCount: number
}

const initialState = {
    colliConfez: {} as ColliConfezRigheResponse,
}

// // The function below is called a thunk and allows us to perform async logic. It
// // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// // will call the thunk with the `dispatch` function as the first argument. Async
// // code can then be executed and other actions can be dispatched. Thunks are
// // typically used to make async requests.
// export const incrementAsync = createAsyncThunk('login/fetchCount', async (amount: number) => {
//     const response = await fetchCount(amount)
//     // The value we return becomes the `fulfilled` action payload
//     return response.data
// })

export const valoriSlice = createSlice({
    name: 'colliConfezRighe',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1
        // },
        // decrement: (state) => {
        //     state.value -= 1
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload
        // },
    },
    // extraReducers: (builder) => {
    //     builder.addMatcher(valoriApi.endpoints.post.matchFulfilled, (state, { payload, type, meta }) => {
    //         console.log(payload)

    //         state[meta.arg.originalArgs.type] = payload
    //     })
    // },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(incrementAsync.pending, (state) => {
    //             state.status = 'loading'
    //         })
    //         .addCase(incrementAsync.fulfilled, (state, action) => {
    //             state.status = 'idle'
    //             state.value += action.payload
    //         })
    //         .addCase(incrementAsync.rejected, (state) => {
    //             state.status = 'failed'
    //         })
    // },
})

export const {} = valoriSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.login.value)`
export const selectState = (state: RootState) => state.colliConfezRighe

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//     (amount: number): AppThunk =>
//     (dispatch, getState) => {
//         const currentValue = selectCount(getState())
//         if (currentValue % 2 === 1) {
//             dispatch(incrementByAmount(amount))
//         }
//     }

export default valoriSlice.reducer
