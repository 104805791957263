import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { ColliConfezRigheObj } from '../colliConfezRighe/colliConfezRigheSlice'

export enum TIPO_BARCODE {
    ORDINE_DI_PRODUZIONE = 'ORDINE_DI_PRODUZIONE',
    ORDINE_CLIENTE = 'ORDINE_CLIENTE',
    ORDINE_DI_PRODUZIONE_ART_ANA = 'ORDINE_DI_PRODUZIONE_ART_ANA',
    COLLO = 'COLLO',
}

export enum FILTRO_TYPE {
    SOLO_ORDINI = 'SOLO_ORDINI',
    SOLO_ANONIMI = 'SOLO_ANONIMI',
    ORDINI_E_ANONIMI = 'ORDINI_E_ANONIMI',
}

export enum SITUAZIONE_TYPE {
    TUTTI = '-1',
    PRONTO_PER_LA_SPEDIZIONE = '0', // 0=Pronto per la spedizione
    CHIUSO_E_ASSEGNATO = '1', // 1=Chiuso e Assegnato
    CHIUSO_E_NON_ASSEGNATO = '2', // 2=Chiuso e non assegnato
    IN_CONFEZIONAMENTO = '3', // 3=In confezionamento
    SOSPESO = '4', // 4=Sospeso
    SPEDITO = '5', // 5=Spedito
    ANNULLATO = '6', // 6=Annullato
}

export interface ColliConfezPaginationFilterRequest {
    gridSordModel?: GridSortModel
    gridFilterModel?: GridFilterModel
    page: number
    pageSize: number

    barcode: string
    filterOrfani: string
    situazione: SITUAZIONE_TYPE
}

export interface ArtAnaRequest {
    barcode: string
}

export interface ColliConfezObj extends ColliConfezObjBarcode {
    isNew?: boolean
}

export interface ColliConfezObjBarcode extends ColliConfezRigheObj {
    // EXTRA VALUES
    TIPO?: string
    DES_RIGA?: string
    DES_ART?: string

    // COLLI_CONFEZ_RIGHE
    // EXTENDS

    // EXTRA VALUES
    COD_CF?: string
    RAG_SOC_CF?: string
    COD_IVA?: string
    COD_DIVISA?: string

    NUM_DEST?: string
    // FROM DEST MERCE
    DES_DEST_MERCE?: string
    INDI_DEST_MERCE?: string
    CAP_DEST_MERCE?: string
    COMUNE_DEST_MERCE?: string
    PROVINCIA_DEST_MERCE?: string
    STATO_DEST_MERCE?: string

    COD_CAUS_DOC?: string
    COD_DEP?: string
    COD_PAGA?: string
    COD_ABI?: string
    COD_CAB?: string
    NUM_CONTO?: string
    QTA?: string

    NR_RIGHE?: string

    TEMPLATE?: string

    SITUAZIONE_COLLO?: SITUAZIONE_TYPE

    PESO_NETTO?: string

    AREA_DECK?: string
}

export interface ColliConfezInsertTable {
    AZIENDA_ID?: number

    ANNO?: string
    PROGR_COLLO?: number
    PROGR_COLLO_ID?: string

    UBICAZIONE?: string
    PESO?: number
    PESO_NETTO?: number
    PESO_IMBALLO?: number
    X_VOLUME?: number
    COD_IMB?: string
    DES_IMB?: string
    NOTE?: string
    NOTE_INT?: string
    SITUAZIONE_COLLO?: SITUAZIONE_TYPE
    DATA_CHIUS_COLLO?: Date
    COD_CF?: string
    COD_IVA?: string
    COD_AGE?: string
    COD_AGE_2?: string
    COD_DIVISA?: string
    COD_CAUS_DOC?: string
    COD_PAGA?: string
    COD_ABI?: string
    COD_CAB?: string
    NUM_CONTO?: string
    FLAG_CORRISPETTIVO?: number
    NUM_DEST?: string
    COD_DEP?: string
    UTILIZZATORE?: string
    FLAG_PESO_MAN?: number
    FLAG_PESO_NETTO_MAN?: number
    FLAG_VOLUME_MAN?: number
    FLAG_PESO_IMB_MAN?: number
    ID_COL_COD?: string
    ID_COL_PROGR?: string
    DATA_PREP?: Date
    LUNGHEZZA?: number
    LARGHEZZA?: number
    ALTEZZA?: number
}

export interface ColliConfezUpdate {
    newRow: ColliConfezInsertTable
    oldRow: ColliConfezInsertTable
}

export interface ArtAna {
    COD_ART?: string
    DES_ART?: string
    COD_CAT?: string
    TIPO_APPR_STAND?: number
    FLAG_APPR_ALT?: number
    COD_ATG?: string
    COD_COSTR?: string
    COD_CAT_STAT?: string
    NOTE_ART?: string
    NUM_LIV_VAR?: number
    NUM_LIV_PREZ_VAR?: number
    FLAG_NO_GIAC?: number
    FLAG_NO_INVENT?: number
    FLAG_LOTTI_E_MATR?: number
    FLAG_INATTIVO?: number
    DATA_INSERIMENTO?: Date
    UM_BASE?: string
    NUM_DEC_UM_BASE?: string
    ARROT_UM_BASE?: number
    FLAG_UM_ALTERNATIVE?: number
    COD_TIPO_VAR?: string
    FLAG_SOTTOMOVIMENTI?: number
    COD_CAT_FISC?: string
    FLAG_CALCOLO_PESO?: number
    AZIENDA_ID?: number
    FLAG_PRIVATO?: number
    FLAG_DIST?: number
    FLAG_DIST_DA_ATG?: number
    COD_TAB_PROVV?: string
    COSTO_RIF_VU1?: number
    PREZZO_RIF_VU1?: number
    QUANT_RIF_DIST?: number
    LOTTO_MINIMO?: number
    LOTTO_ECONOMICO?: number
    FLAG_SOLO_MULTIPLI_MINIMO?: number
    FLAG_ART_FANTASMA?: number
    COD_ART_CONF?: string
    PROGR_ART_GEN?: string
    NOMENCLATURA_COMB_ACQ?: string
    NOMENCLATURA_COMB_VEND?: string
    PROV_ORIG_DEST?: string
    UM_SUPPLEMENTARE?: string
    MOLTIPLICATORE_UM_SUPP?: number
    MASSA_NETTA_ART?: number
    FLAG_DATA_SCAD?: number
    FLAG_SCAD_GG_O_MESI?: number
    GG_O_MESI_SCAD?: string
    FLAG_DATA_PROD?: number
    FLAG_PROMPT_LOTTO?: number
    TIPO_CALC_MRP?: number
    COD_CF?: string
    COD_DEP?: string
    MODO_CALCOLO_PREZZO?: number
    MODO_CALCOLO_SCONTO?: number
    GG_ACCORP_PROD?: number
    GG_LEAD_TIME?: string
    FLAG_OBSOLETO?: number
    DATA_OBSOLESCENZA?: Date
    COD_ART_NEW?: string
    FLAG_INSERT_VAR_CONF?: number
    FLAG_MATRICOLA?: number
    FLAG_MATRICOLA_CAR?: number
    FLAG_MATRICOLA_SCAR?: number
    ROOT_MATR?: string
    COUNTER_MATR?: string
    LEN_PROGR_MATR?: number
    FLAG_ART_CRITICO_MRP?: number
    FLAG_CONF?: number
    X_STATUS?: string
    X_COD_REP?: string
    X_FLAG_IVA_DA_REP?: number
    X_COD_STAGIONATURA?: string
    X_COD_ART_CALO?: string
    X_FLAG_PROD_CONF?: number
    X_COD_CLASS_CONF?: string
    X_COD_IVA_CONF?: string
    X_FLAG_PALMARE?: number
    X_FLAG_OBBLIGO_MATR?: number
    FLAG_NO_MRP?: number
    FLAG_CREA_ORP_DA_ORD_CLI?: number
    X_FLAG_PREPAR_IN_COLLI?: number
    FLAG_SC_FIN_ART?: number
    COD_SC_FIN_ART?: string
    FLAG_COMP_PRINC?: number
    COD_CF_CLI?: string
    FLAG_COMP_VRF_EV_ORDFOR?: number
    DESC_PALMARE?: string
    FLAG_ART_INTRA?: number
    NOMENCLATURA_COMB?: string
    COD_TIPO_FATT?: string
    FLAG_SOGG_RIT_ACC?: number
    FLAG_SOGG_CONTR_PREV?: number
    FLAG_SOGG_CONTR_INT?: number
    FLAG_SOGG_SPESE?: number
    FLAG_RIMBORSO?: number
    TIPO_COSTO_DIST?: string
    FLAG_ART_NO_IN_ORDPRO?: number
    COD_TEDP?: string
    GG_ACCORP_ACQ?: number
    GG_ANT_FABB_ACQ?: number
    LOTTO_PROD_MINIMO?: number
    FLAG_PROD_SOLO_MULT_MIN?: number
    LOTTO_PROD_ECON?: number
    GG_PROD_LEAD_TIME?: string
    GG_ANT_FABB_PROD?: number
    FLAG_PROD_TPRLE?: number
    TIPO_CALC_PROD_LT?: number
    FLAG_IN_ESAUR?: number
    FLAG_VERSAMENTI?: number
    NR_VERSAMENTI?: number
    FLAG_NO_PROVV?: number
    FLAG_TIPO_VAL?: number
    COD_MARCHIO?: string
    COD_STAPRO?: string
    DATA_UV_METEL?: Date
    KY_MDS1_INSART?: string
    COD_DISEGNO?: string
    FLAG_LISTINO_CF_EMAIL?: number
    FLAG_NOCG_PROD_ACCORP?: number
    FLAG_NOCG_PROD_LOTTI?: number
    FLAG_NOCG_PROD_ANTIC?: number
    FLAG_NOCG_PROD_LEAD?: number
    FLAG_NOCG_ACQ_ACCORP?: number
    FLAG_NOCG_ACQ_LOTTI?: number
    FLAG_NOCG_ACQ_ANTIC?: number
    FLAG_NOCG_ACQ_LEAD?: number
    FLAG_ENSRC?: number
    FLAG_WFCGC?: number
    FLAG_NOCG_ESIG_DCPR?: number
    PAESE_ORIG_CESS?: string

    PESO_ART_NETTO: string
}

export interface DefUmUtente {
    NUM_UM?: string
    UM_DESC?: string
    FLAG_UM_ACQ?: string
    FLAG_UM_VEND?: string
    FLAG_UM_MOV?: string
    NUM_UM_ACQ?: string
    NUM_UM_VEND?: number
    NUM_UM_MAG?: number
}

export interface ArtUmObj {
    NUM_UM?: string
    COD_ART_NUM_UM?: string
    COD_ART?: string
    UM?: string
    DESC_UM?: string
    CONF_UM?: string
    CONVERS_UM?: string
    NUM_DEC_UM?: number
    ARROT_UM?: number
    FLAG_CONVERS_EDITABILE?: number
    SOLO_MULTIPLI?: number
}

export interface ArtConfObj {
    NUM_CONF: number
    COD_ART_NUM_CONF: string
    COD_ART: string
    CONF: string
    DESC_CONF: string
    CONVERS_CONF: number
    SOLO_MULTIPLI: number
    UM: string
    NUM_DEC_CONF: number
    ARROT_CONF: number
    FLAG_DEFAULT: number
    DESC_CONF_NEW: string
}

export interface DataArtAnaObj {
    artAna: Array<ArtAna>
    defUmUtente: Array<DefUmUtente>
    artUm: Array<ArtUmObj>
    artConf: Array<ArtConfObj>
}

export interface DataColliConfezObj {
    data: Array<ColliConfezObj>
    dataBarcode: Array<ColliConfezObjBarcode>
    dataArtAna: DataArtAnaObj
    rowCount: number
}

export interface ColliConfezResponse {
    dataColliConfez: DataColliConfezObj
    dataArtAna: DataArtAnaObj
    message: string
}

export interface ColliConfezUpdateResponse {
    data: Array<ColliConfezObj>
    message?: string | null
}

const initialState = {
    colliConfez: {} as ColliConfezResponse,
}

// // The function below is called a thunk and allows us to perform async logic. It
// // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// // will call the thunk with the `dispatch` function as the first argument. Async
// // code can then be executed and other actions can be dispatched. Thunks are
// // typically used to make async requests.
// export const incrementAsync = createAsyncThunk('login/fetchCount', async (amount: number) => {
//     const response = await fetchCount(amount)
//     // The value we return becomes the `fulfilled` action payload
//     return response.data
// })

export const valoriSlice = createSlice({
    name: 'colliConfez',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        increment: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            //
            //state.colliConfez.data[0].NR_RIGHE += 1
        },
        // decrement: (state) => {
        //     state.value -= 1
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload
        // },
    },
    // extraReducers: (builder) => {
    //     builder.addMatcher(valoriApi.endpoints.post.matchFulfilled, (state, { payload, type, meta }) => {
    //         console.log(payload)

    //         state[meta.arg.originalArgs.type] = payload
    //     })
    // },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(incrementAsync.pending, (state) => {
    //             state.status = 'loading'
    //         })
    //         .addCase(incrementAsync.fulfilled, (state, action) => {
    //             state.status = 'idle'
    //             state.value += action.payload
    //         })
    //         .addCase(incrementAsync.rejected, (state) => {
    //             state.status = 'failed'
    //         })
    // },
})

export const { increment } = valoriSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.login.value)`
export const selectState = (state: RootState) => state.colliConfez

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//     (amount: number): AppThunk =>
//     (dispatch, getState) => {
//         const currentValue = selectCount(getState())
//         if (currentValue % 2 === 1) {
//             dispatch(incrementByAmount(amount))
//         }
//     }

export default valoriSlice.reducer
