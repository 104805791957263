import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { FILTRO_TYPE, SITUAZIONE_TYPE } from '../colliConfez/colliConfezSlice'
import { TIPO_IMAGE } from '../immagini/immaginiSlice'

const STORAGE_KEY_AZIENDA = 'AZIENDA_SELECTED'
const STORAGE_KEY_IMBALLO = 'IMBALLO_SELECTED'
const STORAGE_KEY_TIPI_IMAGE_DOC_COLLI_CONFEZ = 'TIPI_IMAGE_DOC_COLLI_CONFEZ_SELECTED'
const STORAGE_KEY_TIPI_IMAGE_DOC_COLLI_CONFEZ_RIGHE = 'TIPI_IMAGE_DOC_COLLI_CONFEZ_RIGHE_SELECTED'
const STORAGE_KEY_TIPI_COD_ART_INTERNO = 'TIPI_COD_ART_INTERNO_SELECTED'
const STORAGE_KEY_BARCODE_TERMINATOR = 'BARCODE_TERMINATOR'

const STORAGE_KEY_SITUAZIONE_COLLI = 'SITUAZIONE_COLLI'
const STORAGE_KEY_ORDINI_ANONIMO = 'ORDINI_ANONIMO'

export interface AziendaObj {
    AZIENDA_ID: number
    RAGIONE_SOCIALE: string
}

export interface ImballoObj {
    COD_IMB: string
    DES_IMB?: string
    TARA_IMB?: number
    VOLUME_IMB?: number
}

export interface TipiImageDoc {
    COD_TIPO_IMAGE_DOC: string
    DES_TIPO_IMAGE_DOC?: string
    LISTA_DOC_EXT_IMAGE?: string
    LISTA_DOC_PRO_IMAGE?: string
    LISTA_DOC_CLAV_IMAGE?: string
    LISTA_COLLI_IMAGE?: string
}

export interface TipiCodArt {
    COD_TIPO_COD: string
    DES_TIPO_COD?: string
    FLAG_TIPO_COD?: string
    FLAG_PALMARE?: number
    FLAG_TIPO_CONTROLLO?: number
    FLAG_METEL_EAN13?: number
    FLAG_TCA_CDC?: number
}

export interface AziendaResponse {
    data: Array<AziendaObj> | []
    message?: string | null
}

export interface ImballoResponse {
    data: Array<ImballoObj> | []
    message?: string | null
}

export interface TipiCodArtResponse {
    data: Array<TipiCodArt> | []
    message?: string | null
}

export interface TipiImageDocRequest {
    tipoImage: TIPO_IMAGE
}

export interface TipiImageDocResponse {
    data: Array<TipiImageDoc> | []
    message?: string | null
}

interface ImpostazioniObj {
    aziendaSelected: AziendaObj
    imballoSelected: ImballoObj
    tipiImageDocColliConfezSelected: TipiImageDoc
    tipiImageDocColliConfezRigheSelected: TipiImageDoc
    tipiCodArtInterno: TipiCodArt
    barcodeTerminator: Array<string>
    situazioneColli: SITUAZIONE_TYPE
    filtroOrdiniAnonimo: FILTRO_TYPE
}

const initialState: ImpostazioniObj = {
    aziendaSelected: JSON.parse(window.localStorage.getItem(STORAGE_KEY_AZIENDA) || '{}') || null,
    imballoSelected: JSON.parse(window.localStorage.getItem(STORAGE_KEY_IMBALLO) || '{}') || null,
    tipiImageDocColliConfezSelected:
        JSON.parse(window.localStorage.getItem(STORAGE_KEY_TIPI_IMAGE_DOC_COLLI_CONFEZ) || '{}') || null,
    tipiImageDocColliConfezRigheSelected:
        JSON.parse(window.localStorage.getItem(STORAGE_KEY_TIPI_IMAGE_DOC_COLLI_CONFEZ_RIGHE) || '{}') || null,
    tipiCodArtInterno: JSON.parse(window.localStorage.getItem(STORAGE_KEY_TIPI_COD_ART_INTERNO) || '{}') || null,
    barcodeTerminator: JSON.parse(window.localStorage.getItem(STORAGE_KEY_BARCODE_TERMINATOR) || '[]') || null,
    situazioneColli: (window.localStorage.getItem(STORAGE_KEY_SITUAZIONE_COLLI) as SITUAZIONE_TYPE) || SITUAZIONE_TYPE.TUTTI,
    filtroOrdiniAnonimo: (window.localStorage.getItem(STORAGE_KEY_ORDINI_ANONIMO) as FILTRO_TYPE) || FILTRO_TYPE.ORDINI_E_ANONIMI,
}

// // The function below is called a thunk and allows us to perform async logic. It
// // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// // will call the thunk with the `dispatch` function as the first argument. Async
// // code can then be executed and other actions can be dispatched. Thunks are
// // typically used to make async requests.
// export const incrementAsync = createAsyncThunk('login/fetchCount', async (amount: number) => {
//     const response = await fetchCount(amount)
//     // The value we return becomes the `fulfilled` action payload
//     return response.data
// })

export const impostazioniSlice = createSlice({
    name: 'impostazioni',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setAzienda: (state, action: PayloadAction<AziendaObj>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.aziendaSelected = action.payload
            window.localStorage.setItem(STORAGE_KEY_AZIENDA, JSON.stringify(action.payload))
        },
        setImballo: (state, action: PayloadAction<ImballoObj>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.imballoSelected = action.payload
            window.localStorage.setItem(STORAGE_KEY_IMBALLO, JSON.stringify(action.payload))
        },
        setTipiImageDocColliConfez: (state, action: PayloadAction<TipiImageDoc>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.tipiImageDocColliConfezSelected = action.payload
            window.localStorage.setItem(STORAGE_KEY_TIPI_IMAGE_DOC_COLLI_CONFEZ, JSON.stringify(action.payload))
        },
        setTipiImageDocColliConfezRighe: (state, action: PayloadAction<TipiImageDoc>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.tipiImageDocColliConfezRigheSelected = action.payload
            window.localStorage.setItem(STORAGE_KEY_TIPI_IMAGE_DOC_COLLI_CONFEZ_RIGHE, JSON.stringify(action.payload))
        },
        setTipiCodArtInterno: (state, action: PayloadAction<TipiCodArt>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.tipiCodArtInterno = action.payload
            window.localStorage.setItem(STORAGE_KEY_TIPI_COD_ART_INTERNO, JSON.stringify(action.payload))
        },
        setBarcodeTerminator: (state, action: PayloadAction<string>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.barcodeTerminator = [...state.barcodeTerminator, action.payload]
            window.localStorage.setItem(STORAGE_KEY_BARCODE_TERMINATOR, JSON.stringify(state.barcodeTerminator))
        },
        deleteBarcodeTerminator: (state, action: PayloadAction<number>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.barcodeTerminator.splice(action.payload, 1)
            window.localStorage.setItem(STORAGE_KEY_BARCODE_TERMINATOR, JSON.stringify(state.barcodeTerminator))
        },
        setFiltroOrdiniAnonimo: (state, action: PayloadAction<FILTRO_TYPE>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.filtroOrdiniAnonimo = action.payload
            window.localStorage.setItem(STORAGE_KEY_ORDINI_ANONIMO, action.payload)
        },
        setSituazioneColli: (state, action: PayloadAction<SITUAZIONE_TYPE>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.situazioneColli = action.payload
            window.localStorage.setItem(STORAGE_KEY_SITUAZIONE_COLLI, action.payload)
        },
        // decrement: (state) => {
        //     state.value -= 1
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload
        // },
    },
    // extraReducers: (builder) => {
    //     builder.addMatcher(valoriApi.endpoints.post.matchFulfilled, (state, { payload, type, meta }) => {
    //         console.log(payload)

    //         state[meta.arg.originalArgs.type] = payload
    //     })
    // },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(incrementAsync.pending, (state) => {
    //             state.status = 'loading'
    //         })
    //         .addCase(incrementAsync.fulfilled, (state, action) => {
    //             state.status = 'idle'
    //             state.value += action.payload
    //         })
    //         .addCase(incrementAsync.rejected, (state) => {
    //             state.status = 'failed'
    //         })
    // },
})

export const {
    setAzienda,
    setImballo,
    setTipiImageDocColliConfez,
    setTipiImageDocColliConfezRighe,
    setTipiCodArtInterno,
    setBarcodeTerminator,
    deleteBarcodeTerminator,
    setSituazioneColli,
    setFiltroOrdiniAnonimo,
} = impostazioniSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.login.value)`
export const selectState = (state: RootState) => state.impostazioni

export const selectAzienda = (state: RootState) => state.impostazioni.aziendaSelected
export const selectImballo = (state: RootState) => state.impostazioni.imballoSelected
export const selectTipiImageDocColliConfez = (state: RootState) => state.impostazioni.tipiImageDocColliConfezSelected
export const selectTipiImageDocColliConfezRighe = (state: RootState) =>
    state.impostazioni.tipiImageDocColliConfezRigheSelected
export const selectTipiCodArtInterno = (state: RootState) => state.impostazioni.tipiCodArtInterno
export const selectBarcodeSelector = (state: RootState) => state.impostazioni.barcodeTerminator
export const selectSituazioneColliDefault = (state: RootState) => state.impostazioni.situazioneColli
export const selectFiltroOrdiniAnonimoDefault = (state: RootState) => state.impostazioni.filtroOrdiniAnonimo

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const setAziendaStored =
//     (azienda: AziendaObj): AppThunk =>
//     (dispatch, getState) => {
//         const aziendaSelected = selectAzienda(getState())
//         console.log(aziendaSelected)
//         dispatch(setAzienda(azienda))
//     }

export default impostazioniSlice.reducer
