import AssistantDirectionTwoToneIcon from '@mui/icons-material/AssistantDirectionTwoTone'
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InventoryTwoToneIcon from '@mui/icons-material/InventoryTwoTone'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Stack, Typography } from '@mui/material'
import { LabelDetail } from '../../components/LabelDetail'
import { SITUAZIONI } from './ComboBoxSituazione'
import { ColliConfezObjBarcode, TIPO_BARCODE } from './colliConfezSlice'
interface Props {
    dataBarcode: ColliConfezObjBarcode[]
    onCancel: () => void
}
export const RiepilogoBarcode = ({ dataBarcode, onCancel }: Props) => {
    if (dataBarcode?.length === 0) return <Typography textAlign="center"></Typography>

    let bb
    let tt

    if (dataBarcode?.length > 0) {
        bb = dataBarcode[0]
        switch (bb.TIPO) {
            case TIPO_BARCODE.ORDINE_DI_PRODUZIONE:
                tt = 'Ordine di Produzione'
                break
            case TIPO_BARCODE.ORDINE_CLIENTE:
                tt = 'Ordine cliente'
                break
            case TIPO_BARCODE.ORDINE_DI_PRODUZIONE_ART_ANA:
                tt = 'Ordine di Produzione senza Ordine Cliente'
                break
            case TIPO_BARCODE.COLLO:
                tt = 'Collo'
                break
        }
    }

    const SITUAZIONE_COLLO: number = Number(bb?.SITUAZIONE_COLLO != null ? bb.SITUAZIONE_COLLO : -1) + 1

    return (
        <>
            <Grid sx={{ mt: 0.5 }} spacing={1} container direction="row" alignItems="stretch">
                <Grid item xs={11}>
                    <Accordion variant="elevation" TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center">
                                <InventoryTwoToneIcon sx={{ fontSize: '48px', mr: 2 }} />
                                {SITUAZIONE_COLLO > 0 &&
                                    SITUAZIONE_COLLO <= 7 && ( // att.ne visualizzo solo per i tipi previsti
                                        <Box sx={{ mr: 1 }}>{SITUAZIONI[SITUAZIONE_COLLO].icon}</Box>
                                    )}
                                <LabelDetail
                                    label={bb?.PROGR_COLLO_ID || bb?.DOC_RIGA_ID || bb?.DOC_ID}
                                    labelTitle="Link"
                                />
                                <LabelDetail label={tt} labelTitle="Tipo" />
                                <LabelDetail label={bb?.COD_ART} labelTitle="Cod.Art." />
                                <LabelDetail label={bb?.DES_RIGA} labelTitle="Desc.Art." />
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack direction={{ xs: 'column', sm: 'row' }}>
                                <LabelDetail label={bb?.QTA} labelTitle="Q.tà Ordine o Prodotta" />
                                <LabelDetail label={bb?.COD_CF} labelTitle="Cod.Cliente" />
                                <LabelDetail label={bb?.RAG_SOC_CF} labelTitle="Rag.Sociale" />
                                <LabelDetail label={bb?.PESO_NETTO} labelTitle="Peso netto" />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion variant="elevation" TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Stack direction={{ xs: 'column', sm: 'row' }}>
                                <AssistantDirectionTwoToneIcon sx={{ fontSize: '48px', mr: 2 }} />
                                <LabelDetail label={bb?.NUM_DEST} labelTitle="Num.Dest." />
                                <LabelDetail label={bb?.DES_DEST_MERCE} labelTitle="Descrizione" />
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack direction={{ xs: 'column', sm: 'row' }}>
                                <LabelDetail label={bb?.INDI_DEST_MERCE} labelTitle="Indirizzo" />
                                <LabelDetail label={bb?.CAP_DEST_MERCE} labelTitle="Cap" />
                                <LabelDetail label={bb?.COMUNE_DEST_MERCE} labelTitle="Comune" />
                                <LabelDetail label={bb?.PROVINCIA_DEST_MERCE} labelTitle="Provincia" />
                                <LabelDetail label={bb?.STATO_DEST_MERCE} labelTitle="Stato" />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={1}>
                    <Button
                        fullWidth={true}
                        sx={{ height: '100%' }}
                        variant="contained"
                        onClick={() => {
                            onCancel()
                        }}
                    >
                        <CancelTwoToneIcon />
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}
