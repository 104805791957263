import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone'
import KeyboardArrowUpTwoToneIcon from '@mui/icons-material/KeyboardArrowUpTwoTone'
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone'
import MenuIcon from '@mui/icons-material/Menu'
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone'
import { Button, Fab, ListItemButton, Slide, useScrollTrigger, Zoom } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppSelector } from './app/hooks'
import { useLogoutPostMutation } from './features/login/loginAPI'
import { LoginRequest, selectUser } from './features/login/loginSlice'
import SettingsApplicationsTwoToneIcon from '@mui/icons-material/SettingsApplicationsTwoTone'
import DataObjectTwoToneIcon from '@mui/icons-material/DataObjectTwoTone'
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone'
import { useState } from 'react'
import DialogInfo from './DialogInfo'
const drawerWidth = 240
interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window
    children: React.ReactElement
    ToggleTheme?: React.ReactElement
}

function ScrollTop(props: Props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    })

    const handleClickAnchor = (event: React.MouseEvent<HTMLDivElement> | null) => {
        let anchor
        if (event) {
            anchor = ((event?.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor')
        } else {
            anchor = document.querySelector('#back-to-top-anchor')
        }

        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }
    }

    return (
        <Zoom in={trigger}>
            <Box onClick={handleClickAnchor} role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                {children}
            </Box>
        </Zoom>
    )
}

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window
    children: React.ReactElement
}

function HideOnScroll(props: Props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    })

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    )
}

export default function ResponsiveDrawer(props: Props) {
    const user = useAppSelector(selectUser)

    const [openInfoDialog, setOpenInfoDialog] = useState(false)

    const { window, children, ToggleTheme } = props
    const [mobileOpen, setMobileOpen] = React.useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const navigate = useNavigate()

    const [
        postLogout, // This is the mutation trigger
        { isLoading, error }, // This is the destructured mutation result
    ] = useLogoutPostMutation()

    const handleClick = async () => {
        const rest = await postLogout({
            password: user?.password,
            email: user?.email,
        } as LoginRequest).unwrap()
        console.log(rest)
        setTimeout(() => {
            navigate('/login')
        }, 1000)
    }

    const drawer = (
        <div>
            {openInfoDialog === true && <DialogInfo handleClose={() => setOpenInfoDialog(false)} />}
            <List>
                <ListItem component={Link} to="/" button onClick={handleDrawerToggle}>
                    <ListItemIcon>{<HomeTwoToneIcon />}</ListItemIcon>
                    <ListItemText primary={'Home'} />
                </ListItem>
            </List>
            <Divider />
            <List>
                {user?.id == null ? (
                    <>
                        <ListItem component={Link} to="/Login" button onClick={handleDrawerToggle}>
                            <ListItemIcon>{<LockTwoToneIcon />}</ListItemIcon>
                            <ListItemText primary={'Login'} />
                        </ListItem>
                    </>
                ) : (
                    <>
                        <ListItem component={Link} to="/impostazioni" button onClick={handleDrawerToggle}>
                            <ListItemIcon>{<SettingsApplicationsTwoToneIcon />}</ListItemIcon>
                            <ListItemText primary={'Impostazioni'} />
                        </ListItem>
                        <ListItemButton component="a" onClick={handleClick}>
                            <ListItemIcon>{<LockTwoToneIcon />}</ListItemIcon>
                            <ListItemText primary={user.email} secondary="logout" />
                        </ListItemButton>
                    </>
                )}
            </List>
            <Divider />
            <List>
                <ListItem>
                    <ListItemIcon>{<DataObjectTwoToneIcon />}</ListItemIcon>
                    <ListItemText primary={`${process.env.REACT_APP_VERSION}`} secondary={'Versione webApp'} />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem target={'_blank'} component="a" href="https://www.prosyt.it">
                    <ListItemIcon>{<PaidTwoToneIcon />}</ListItemIcon>
                    <ListItemText primary={'Prosyt'} />
                </ListItem>
            </List>
        </div>
    )

    const container = window !== undefined ? () => window().document.body : undefined

    return (
        <>
            <HideOnScroll>
                <AppBar position="fixed">
                    <Toolbar variant="dense">
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="subtitle1" noWrap component="div">
                            Colli
                        </Typography>
                        {ToggleTheme}
                        <InfoTwoToneIcon
                            onClick={() => {
                                setOpenInfoDialog((d) => !d)
                            }}
                        />
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            {children}
            <ScrollTop>
                <Fab color="secondary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpTwoToneIcon />
                </Fab>
            </ScrollTop>
        </>
    )
}
