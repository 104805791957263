import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

const STORAGE_KEY_DEFAULT_CAMERA_SELECTED = 'DEFAULT_CAMERA_SELECTED'

export interface BarcodeRequest {}

export interface Count {
    count: number
}

export interface BarcodeResponse {
    data: Array<Count>
    message?: string | null
}

export interface State {
    cameraDefault: MediaDeviceInfo
}

const initialState: State = {
    cameraDefault: JSON.parse(window.localStorage.getItem(STORAGE_KEY_DEFAULT_CAMERA_SELECTED) || '{}') || null,
}

export const barcodeSlice = createSlice({
    name: 'barcode',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setCameraDefault: (state, action: PayloadAction<MediaDeviceInfo>) => {
            
            state.cameraDefault = action.payload
            window.localStorage.setItem(STORAGE_KEY_DEFAULT_CAMERA_SELECTED, JSON.stringify(action.payload))
        },
    },
})

export const { setCameraDefault } = barcodeSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.login.value)`
export const selectCameraDefault = (state: RootState) => state.barcode.cameraDefault

export default barcodeSlice.reducer
