import { Box, Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material'
import 'moment/locale/it'

import DatasetLinkedTwoToneIcon from '@mui/icons-material/DatasetLinkedTwoTone'
import LiveHelpTwoToneIcon from '@mui/icons-material/LiveHelpTwoTone'
import { FILTRO_TYPE } from './colliConfezSlice'

interface Props {
    filtro: string
    callback: (value: FILTRO_TYPE) => void
}

export const FILTRO = [
    {
        value: FILTRO_TYPE.SOLO_ORDINI,
        label: 'Solo ordini',
        icon: <DatasetLinkedTwoToneIcon color="primary" />,
    },
    {
        value: FILTRO_TYPE.SOLO_ANONIMI,
        label: 'Solo anonimi',
        icon: <LiveHelpTwoToneIcon color="primary" />,
    },
    {
        value: FILTRO_TYPE.ORDINI_E_ANONIMI,
        label: 'Ordini e Anonimi',
        icon: (
            <>
                <DatasetLinkedTwoToneIcon color="primary" />
                <LiveHelpTwoToneIcon color="primary" />
            </>
        ),
    },
]

export function ComboBoxFiltro({ callback, filtro }: Props) {
    const handleChangeFiltro = (event: SelectChangeEvent) => {
        callback(event.target.value as FILTRO_TYPE)
    }

    return (
        <Box>
            <FormControl fullWidth size="small">
                {<InputLabel id="label-filtro">Filtro</InputLabel>}
                <Select value={filtro} label={'Situazione colli'} onChange={handleChangeFiltro}>
                    {FILTRO.map(({ value, icon, label }: any) => {
                        return (
                            <MenuItem key={value} value={value}>
                                <Stack spacing={1} direction="row" divider={<Divider orientation="vertical" />}>
                                    {icon} {label}
                                </Stack>
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Box>
    )
}
