import ApprovalTwoToneIcon from '@mui/icons-material/ApprovalTwoTone'
import CancelScheduleSendTwoToneIcon from '@mui/icons-material/CancelScheduleSendTwoTone'
import DoDisturbTwoToneIcon from '@mui/icons-material/DoDisturbTwoTone'
import FlightTakeoffTwoToneIcon from '@mui/icons-material/FlightTakeoffTwoTone'
import InsertLinkTwoToneIcon from '@mui/icons-material/InsertLinkTwoTone'
import LinkOffTwoToneIcon from '@mui/icons-material/LinkOffTwoTone'
import PhotoCameraTwoToneIcon from '@mui/icons-material/PhotoCameraTwoTone'
import SyncTwoToneIcon from '@mui/icons-material/SyncTwoTone'
import { alpha, Box, Button, Divider, Grid, LinearProgress, Paper, Stack, styled, Typography } from '@mui/material'
import { deepPurple } from '@mui/material/colors'
import {
    DataGridPro,
    GridCallbackDetails,
    GridColDef,
    GridFilterModel,
    GridRenderCellParams,
    GridRowClassNameParams,
    GridRowId,
    GridRowModesModel,
    GridRowsProp,
    GridSortItem,
    GridSortModel,
    GridToolbarContainer,
    GridValidRowModel,
} from '@mui/x-data-grid-pro'
import moment from 'moment'
import 'moment/locale/it'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useAppSelector } from '../../app/hooks'
import { Barcode } from '../barcode/Barcode'
import { ColliConfezRighe } from '../colliConfezRighe/ColliConfezRighe'
import { Immagini } from '../immagini/Immagini'
import { selectCameraImmaginiDefault, TIPO_IMAGE } from '../immagini/immaginiSlice'
import {
    selectAzienda,
    selectFiltroOrdiniAnonimoDefault,
    selectImballo,
    selectSituazioneColliDefault,
    selectTipiCodArtInterno,
    selectTipiImageDocColliConfez,
    selectTipiImageDocColliConfezRighe,
} from '../impostazioni/impostazioniSlice'
import { selectState } from '../stampe/stampeSlice'

import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone'
import PrintTwoToneIcon from '@mui/icons-material/PrintTwoTone'
import { selectCameraDefault } from '../barcode/barcodeSlice'
import { selectUser } from '../login/loginSlice'
import { Stampe } from '../stampe/Stampe'
import {
    ColliConfezInsertTable,
    ColliConfezObj,
    ColliConfezObjBarcode,
    ColliConfezUpdate,
    DataArtAnaObj,
    FILTRO_TYPE,
    SITUAZIONE_TYPE,
    TIPO_BARCODE,
} from './colliConfezSlice'
import { ComboBoxFiltro, FILTRO } from './ComboBoxFiltro'
import { ComboBoxSituazione, SITUAZIONI } from './ComboBoxSituazione'
import { ComboBoxSituazioneDialog } from './ComboBoxSituazioneDialog'

import { ArtAna } from '../artAna/ArtAna'

import FaceTwoToneIcon from '@mui/icons-material/FaceTwoTone'
import { useInsertColliConfezMutation, useLazyGetColliConfezQuery, useUpdateColliConfezMutation } from '../../app/api'
import { snackActions } from '../../SnackbarUtilsConfigurator'
import { RiepilogoBarcode } from './RiepilogoBarcode'

const PAGE_SIZE = 10

const pattern = /^(\d+)-0*(\d+)$/
const dataCorrente = new Date()
const annoCorrente = dataCorrente.getFullYear() % 100

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`.super-app-theme-selected`]: {
        backgroundColor: alpha(theme.palette.primary.main, 0.5),
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, 0.5),
        },
    },
}))

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
}

interface ColliConfezProps {
    gridColor?: string
}

export function ColliConfez({ gridColor }: ColliConfezProps) {
    ;[...(document.querySelectorAll('div') as any)]
        .filter((el) => el.textContent.includes('Missing license key'))
        .reverse()[0]
        ?.remove()

    const [colliConfezObjSelected, setColliConfezObjSelected] = React.useState<ColliConfezObj | null>(null)

    // INSERT or UPDATE

    const [updateColliConfez] = useUpdateColliConfezMutation()

    const [insertColliConfez] = useInsertColliConfezMutation()

    // PAGINATION

    interface GridSortFilterModel {
        sortModel: GridSortModel
        filterModel: GridFilterModel
    }

    const [queryOptions, setQueryOptions] = useState<GridSortFilterModel>({
        sortModel: [
            {
                field: 'PROGR_COLLO_ID',
                sort: 'desc',
            },
        ] as GridSortItem[],
        filterModel: {} as GridFilterModel,
    })

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions((qo) => {
            return { sortModel: [...sortModel], filterModel: queryOptions.filterModel }
        })
        handleRefresh()
    }, [])

    const situazioneColliDefault = useAppSelector(selectSituazioneColliDefault)
    const filtroOrdiniAnonimoDefault = useAppSelector(selectFiltroOrdiniAnonimoDefault)

    const [rows, setRows] = useState<Array<ColliConfezObj>>([])
    const [rowsCount, setRowsCount] = useState<number>(0)
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: PAGE_SIZE,
        page: 0,
    })
    const [barcode, setBarcode] = useState<string>('')

    const [filterAO, setFilterAO] = useState<FILTRO_TYPE>(filtroOrdiniAnonimoDefault)
    const [situazione, setSituazione] = useState<SITUAZIONE_TYPE>(situazioneColliDefault)

    const [barcodeOrd, setBarcodeOrd] = useState<string | null>(null)
    const [dataB, setDataB] = useState<{
        dataBarcode: Array<ColliConfezObjBarcode>
        dataArtAna: DataArtAnaObj
    } | null>(null)
    const [dataArtAna, setDataArtAna] = useState<DataArtAnaObj | null>(null)
    const [refresh, setRefresh] = useState<number>(0)

    const [queryColliConfez, { isFetching, isLoading }] = useLazyGetColliConfezQuery()

    useEffect(() => {
        const initColliConfez = async () => {
            // non è ancora stato selezionato ART_ANA secco quindi
            const bb =
                dataArtAna?.artAna != null && dataArtAna?.artAna.length > 0 && dataB?.dataBarcode != null
                    ? barcodeOrd || ''
                    : barcode
            const resp = await queryColliConfez(
                {
                    gridSordModel: queryOptions?.sortModel,
                    gridFilterModel: queryOptions?.filterModel,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                    barcode: bb,
                    filterOrfani: filterAO,
                    situazione,
                },
                false
            ).unwrap()

            if (resp.message === 'findAllColliConfez') {
                setRows(resp.dataColliConfez.data || [])
                setRowsCount(resp.dataColliConfez.rowCount || 0)
                setDataB(resp.dataColliConfez)
                setBarcodeOrd(barcode)
            }

            if (resp.message === 'findAllArtAna') {
                setDataArtAna(resp?.dataArtAna as DataArtAnaObj)
            }
        }
        initColliConfez()
    }, [refresh])

    // FILTER

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        // Here you save the data you need from the filter model
        setQueryOptions((qo) => {
            return { filterModel: { ...filterModel }, sortModel: qo.sortModel }
        })
    }, [])

    const updateSituazione = async (value: string) => {
        try {
            if (colliConfezObjSelected != null) {
                const newRow: ColliConfezInsertTable = {
                    PROGR_COLLO_ID: colliConfezObjSelected.PROGR_COLLO_ID,
                    SITUAZIONE_COLLO: value as SITUAZIONE_TYPE,
                }

                const oldRow: ColliConfezInsertTable = {
                    PROGR_COLLO_ID: colliConfezObjSelected.PROGR_COLLO_ID,
                    SITUAZIONE_COLLO: value as SITUAZIONE_TYPE,
                }

                const cc: ColliConfezUpdate = {
                    newRow,
                    oldRow,
                }

                const response = await updateColliConfez(cc).unwrap()

                if (response.message === 'updateData') {
                    const data = response.data as Array<ColliConfezObj>
                    if (data.length > 0) {
                        snackActions.success(`Riga rif. ordine : ${response.data[0].PROGR_COLLO_ID}`)
                        setColliConfezObjSelected(null)
                        handleRefresh()
                    } else {
                        snackActions.error(response.message)
                    }
                }
            }
        } catch (error: any) {
            console.log(error)
            snackActions.error(error)
        }
    }

    const [rowsIdsDetails, setRowsIdsDetails] = useState<GridRowId[]>([])
    useEffect(() => {
        if (dataB?.dataBarcode != null && dataB.dataBarcode.length > 0) {
            if (rows.length === 1) {
                if (
                    filtroOrdiniAnonimoDefault !== FILTRO_TYPE.ORDINI_E_ANONIMI ||
                    situazioneColliDefault !== SITUAZIONE_TYPE.TUTTI
                ) {
                    setRowsIdsDetails([rows[0].PROGR_COLLO_ID as string])
                }
            }
        }
    }, [dataB])

    const [immaginiSelected, setImmaginiSelected] = useState<ColliConfezObj | null>(null)
    const [stampaSelected, setStampaSelected] = useState<ColliConfezObj | null>(null)

    function renderSituazione(params: GridRenderCellParams<any, any, Number>) {
        switch (params.row.SITUAZIONE_COLLO) {
            case SITUAZIONI[1].value: // 0=Pronto per la spedizione
                return (
                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                        {/* <Typography variant="caption">Pronto per la spedizione</Typography> */}
                        <ApprovalTwoToneIcon color="success" />
                    </Stack>
                )
            case SITUAZIONI[2].value: // 1=Chiuso e Assegnato
                return (
                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                        {/* <Typography variant="caption">Chiuso e assegnato</Typography> */}
                        <InsertLinkTwoToneIcon color="info" />
                    </Stack>
                )
            case SITUAZIONI[3].value: // 2=Chiuso e non assegnato
                return (
                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                        {/* <Typography variant="caption">Chiuso e non assegnato</Typography> */}
                        <LinkOffTwoToneIcon color="disabled" />
                    </Stack>
                )
            case SITUAZIONI[4].value: // 3=In confezionamento
                return (
                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                        {/* <Typography variant="caption">In confezionamento</Typography> */}
                        <LinearProgress sx={{ width: '24px' }} color="primary" />
                    </Stack>
                )
            case SITUAZIONI[5].value: // 4=Sospeso
                return (
                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                        {/* <Typography variant="caption">Sospeso</Typography> */}
                        <CancelScheduleSendTwoToneIcon color="warning" />
                    </Stack>
                )
            case SITUAZIONI[6].value: // 5=Spedito
                return (
                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                        {/* <Typography variant="caption">Spedito</Typography> */}
                        <FlightTakeoffTwoToneIcon color="primary" />
                    </Stack>
                )
            case SITUAZIONI[7].value: // 6=Annullato
                return (
                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                        {/* <Typography variant="caption">Annullato</Typography> */}
                        <DoDisturbTwoToneIcon color="error" />
                    </Stack>
                )
        }
    }

    function renderDataPrep(params: GridRenderCellParams<any, any, Number>) {
        var dateMomentObject = moment(params.row.DATA_PREP, 'DD/MM/YYYY')
        const format = dateMomentObject.format('ddd D MMM YYYY')
        return <Typography variant="caption">{format}</Typography>
    }

    function renderProgrColloId(params: GridRenderCellParams<any, any, Number>) {
        const PROGR_COLLO_ID = params.row.PROGR_COLLO_ID

        var match = params.row.PROGR_COLLO_ID?.match(pattern)

        if (match && match.length > 0) {
            const primoGruppo = match[1]
            if (annoCorrente != primoGruppo) {
                // gli anni non corrispondono all'anno in corso
                return <Typography variant="h6">{PROGR_COLLO_ID}</Typography>
            } else if (match && match.length > 1) {
                const secondoGruppo = match[2]
                return <Typography variant="h6">{secondoGruppo}</Typography>
            } else {
                return <Typography variant="h6">{PROGR_COLLO_ID}</Typography>
            }
        }
        return <Typography variant="h6">{params.row.PROGR_COLLO_ID}</Typography>
    }

    function renderRagSoc(params: GridRenderCellParams<any, any, Number>) {
        return <Typography variant="h6">{params.row.RAG_SOC_CF}</Typography>
    }

    function renderImmagini(params: GridRenderCellParams<any, any, Number>) {
        const currentRow = params.row as ColliConfezObj
        return (
            <PhotoCameraTwoToneIcon
                onClick={() => {
                    setImmaginiSelected(currentRow)
                }}
            />
        )
    }

    function renderStampa(params: GridRenderCellParams<any, any, Number>) {
        const currentRow = params.row as ColliConfezObj

        return (
            <PrintTwoToneIcon
                onClick={() => {
                    setStampaSelected(currentRow)
                }}
            />
        )
    }

    function renderCount(params: GridRenderCellParams<any, any, String>) {
        switch (params.row.TYPEFILTER) {
            case FILTRO[0].value:
                return (
                    <Stack direction="row" alignItems="center" gap={1}>
                        {FILTRO[0].icon}
                        <Typography variant="caption">{params.row.NR_RIGHE}</Typography>
                    </Stack>
                )
            case FILTRO[1].value:
                return (
                    <Stack direction="row" alignItems="center" gap={1}>
                        {FILTRO[1].icon}
                        <Typography variant="caption">{params.row.NR_RIGHE}</Typography>
                    </Stack>
                )
            case FILTRO[2].value:
                return (
                    <Stack direction="row" alignItems="center" gap={1}>
                        {FILTRO[2].icon}
                        <Typography variant="caption">{params.row.NR_RIGHE}</Typography>
                    </Stack>
                )
        }
    }

    const commonColProps: GridValidRowModel = {
        // flex: 1,
        // minWidth: 40,
        editable: false,
        type: 'string',
        hide: false,
        align: 'right',
        headerAlign: 'right',
    }

    const columns: Array<GridColDef> = [
        {
            field: 'PROGR_COLLO_ID',
            headerName: 'Progr.Collo',
            ...commonColProps,
            minWidth: 110,
            renderCell: renderProgrColloId,
        },
        {
            field: 'NR_RIGHE',
            headerName: 'Righe',
            ...commonColProps,
            minWidth: 30,
            renderCell: renderCount,
        },
        {
            flex: 1,
            field: 'RAG_SOC_CF',
            headerName: 'Rag.Soc.',
            ...commonColProps,
            minWidth: 200,
            renderCell: renderRagSoc,
        },
        {
            field: 'DATA_PREP',
            headerName: 'Data prep.',
            filterable: false,
            ...commonColProps,
            renderCell: renderDataPrep,
            minWidth: 120,
        },
        {
            field: 'SITUAZIONE_COLLO',
            headerName: '🖋',
            renderCell: renderSituazione,
            ...commonColProps,
            filterable: false,
            sortable: false,
        },
        {
            field: 'IMMAGINI',
            headerName: '🖋',
            renderCell: renderImmagini,
            ...commonColProps,
            sortable: false,
            filterable: false,
            maxWidth: 40,
        },
        {
            field: 'STAMPA',
            headerName: '',
            renderCell: renderStampa,
            ...commonColProps,
            sortable: false,
            filterable: false,
            maxWidth: 40,
        },
    ]

    const aziendaSelected = useAppSelector(selectAzienda)
    const imballoSelected = useAppSelector(selectImballo)
    const tipiImageDocColliConfez = useAppSelector(selectTipiImageDocColliConfez)
    const tipiImageDocColliConfezRighe = useAppSelector(selectTipiImageDocColliConfezRighe)
    const tipiCodArtInterno = useAppSelector(selectTipiCodArtInterno)

    const cameraDefault = useAppSelector(selectCameraDefault)
    const cameraImmaginiDefault = useAppSelector(selectCameraImmaginiDefault)

    const stampeTemplate = useAppSelector(selectState)

    const user = useAppSelector(selectUser)

    const handleAnonimo = async () => {
        try {
            const cc: ColliConfezInsertTable = {
                AZIENDA_ID: aziendaSelected.AZIENDA_ID,
                COD_IMB: imballoSelected.COD_IMB,
                DES_IMB: imballoSelected.DES_IMB,
                PESO_IMBALLO: imballoSelected.TARA_IMB,
                PESO: 0,
                PESO_NETTO: 0,
                X_VOLUME: 0,
                SITUAZIONE_COLLO: situazione as SITUAZIONE_TYPE,
                UTILIZZATORE: user?.UTENTE,
                FLAG_CORRISPETTIVO: 0,
                FLAG_PESO_IMB_MAN: 0,
                FLAG_PESO_MAN: 0,
                FLAG_PESO_NETTO_MAN: 0,
                FLAG_VOLUME_MAN: 0,
                LUNGHEZZA: 0,
                LARGHEZZA: 0,
                ALTEZZA: 0,
            }

            const response: any = await insertColliConfez(cc).unwrap()

            if (response.message === 'insertData') {
                const data = response.data as Array<ColliConfezObj>
                if (data.length > 0) {
                    snackActions.success('Bancale anonimo id : ' + data[0].PROGR_COLLO_ID)
                    handleRefresh()
                } else {
                    snackActions.error(response.message)
                }
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    const handleOrdine = async () => {
        try {
            const dataBarcode = dataB?.dataBarcode
            if (dataBarcode != null && dataBarcode.length > 0) {
                const cc: ColliConfezInsertTable = {
                    AZIENDA_ID: aziendaSelected.AZIENDA_ID,
                    COD_IMB: imballoSelected.COD_IMB,
                    DES_IMB: imballoSelected.DES_IMB,
                    PESO_IMBALLO: imballoSelected.TARA_IMB,

                    COD_CF: dataBarcode[0].COD_CF,
                    COD_IVA: dataBarcode[0].COD_IVA,
                    COD_DIVISA: dataBarcode[0].COD_DIVISA,
                    COD_DEP: dataBarcode[0].COD_DEP,
                    COD_PAGA: dataBarcode[0].COD_PAGA,
                    COD_ABI: dataBarcode[0].COD_ABI,
                    COD_CAB: dataBarcode[0].COD_CAB,
                    NUM_CONTO: dataBarcode[0].NUM_CONTO,
                    NUM_DEST: dataBarcode[0].NUM_DEST,
                    COD_CAUS_DOC: dataBarcode[0].COD_CAUS_DOC,

                    PESO: 0,
                    PESO_NETTO: 0,
                    X_VOLUME: 0,
                    SITUAZIONE_COLLO: situazione as SITUAZIONE_TYPE,
                    UTILIZZATORE: user?.UTENTE,
                    FLAG_CORRISPETTIVO: 0,
                    FLAG_PESO_IMB_MAN: 0,
                    FLAG_PESO_MAN: 0,
                    FLAG_PESO_NETTO_MAN: 0,
                    FLAG_VOLUME_MAN: 0,
                    LUNGHEZZA: 0,
                    LARGHEZZA: 0,
                    ALTEZZA: 0,
                }

                const response: any = await insertColliConfez(cc).unwrap()

                if (response.message === 'insertData') {
                    const data = response.data as Array<ColliConfezObj>
                    if (data.length > 0) {
                        snackActions.success(
                            'Riga rif. ordine cod.cliente : ' +
                                response.data[0].COD_CF +
                                '    id : ' +
                                response.data[0].PROGR_COLLO_ID
                        )
                        handleRefresh()
                    } else {
                        snackActions.error(response.message)
                    }
                }
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    const handleRefresh = async () => {
        setRefresh((r) => r + 1)
    }

    function EditToolbar(props: EditToolbarProps) {
        //const { setRows, setRowModesModel } = props

        return (
            <GridToolbarContainer>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Stack>
                            <ComboBoxSituazione
                                situazione={situazione}
                                callback={(value: SITUAZIONE_TYPE) => {
                                    setSituazione(value)
                                    setPaginationModel({ ...paginationModel, page: 0 })
                                    handleRefresh()
                                }}
                            />
                            <Button
                                disabled={filterAO === FILTRO[0].value || situazione === SITUAZIONE_TYPE.TUTTI}
                                sx={{ m: 1 }}
                                variant="contained"
                                startIcon={<AddBoxTwoToneIcon />}
                                onClick={handleAnonimo}
                            >
                                CREA BANCALE ANONIMO
                            </Button>
                            {dataB?.dataBarcode != null &&
                                dataB?.dataBarcode.length > 0 && // Propongo il bottone solo se il risultato prevede l'ordine_cliente o l'ordine_di_produzione (altrimenti potrei/sicuramente non avere i dati del cliente)
                                (dataB?.dataBarcode[0].TIPO === TIPO_BARCODE.ORDINE_CLIENTE ||
                                    dataB?.dataBarcode[0].TIPO === TIPO_BARCODE.ORDINE_DI_PRODUZIONE) && (
                                    <Button
                                        disabled={filterAO === FILTRO[1].value || situazione === SITUAZIONE_TYPE.TUTTI}
                                        sx={{ m: 1 }}
                                        variant="contained"
                                        startIcon={<FaceTwoToneIcon />}
                                        onClick={handleOrdine}
                                    >
                                        CREA BANCALE CON IL CLIENTE
                                    </Button>
                                )}
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction="column">
                            <Box sx={{ m: 1 }}>
                                <ComboBoxFiltro
                                    filtro={filterAO}
                                    callback={(value: FILTRO_TYPE) => {
                                        setFilterAO(value)
                                        handleRefresh()
                                    }}
                                />
                            </Box>
                            <Button size="large" sx={{ m: 1 }} variant="contained" onClick={handleRefresh}>
                                <SyncTwoToneIcon />
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </GridToolbarContainer>
        )
    }

    // DESIGN

    if (aziendaSelected.AZIENDA_ID == null) {
        return <Typography variant="h5">Non è stata selezionata l'azienda nelle impostazioni</Typography>
    }
    if (imballoSelected.COD_IMB == null) {
        return <Typography variant="h5">Non è stata selezionato l'imballo nelle impostazioni</Typography>
    }
    if (tipiImageDocColliConfez.COD_TIPO_IMAGE_DOC == null) {
        return (
            <Typography variant="h5">
                Non è stata selezionato il tipo immagine per i colli confezioni nelle impostazioni
            </Typography>
        )
    }
    if (tipiImageDocColliConfezRighe.COD_TIPO_IMAGE_DOC == null) {
        return (
            <Typography variant="h5">
                Non è stata selezionato il tipo immagine per i colli confezioni righe nelle impostazioni
            </Typography>
        )
    }
    if (tipiCodArtInterno.COD_TIPO_COD == null) {
        return (
            <Typography variant="h5">
                Non è stata selezionato il "Tipi codice articoli interni" nelle impostazioni
            </Typography>
        )
    }

    if (stampeTemplate.template1.name == null) {
        return <Typography variant="h5">Non è stata selezionato la stampante per il report 1.</Typography>
    }
    if (stampeTemplate.template2.name == null) {
        return <Typography variant="h5">Non è stata selezionato la stampante per il report 2.</Typography>
    }
    if (stampeTemplate.template3.name == null) {
        return <Typography variant="h5">Non è stata selezionato la stampante per il report 3.</Typography>
    }
    if (stampeTemplate.template4.name == null) {
        return <Typography variant="h5">Non è stata selezionato la stampante per il report 4.</Typography>
    }

    if (cameraDefault.deviceId == null) {
        return (
            <Typography variant="h5">
                Non è stata selezionato la fotocamera di default per l'acquisizione dei barcode
            </Typography>
        )
    }
    if (cameraImmaginiDefault.deviceId == null) {
        return (
            <Typography variant="h5">
                Non è stata selezionato la fotocamera di default per l'acquisizione delle immagini
            </Typography>
        )
    }

    return (
        <>
            <Barcode
                prevBarcode={barcode}
                title="Cod. Ordine ➡ Cod. Ordine di Produzione ➡ Num. completo Collo ➡ [+] Cod.Articolo"
                callbackValue={(value) => {
                    console.log('RICERCA -> ' + value)
                    setBarcode(value)
                    handleRefresh()
                }}
                callbackCancel={() => {
                    setDataB(null)
                    setBarcode('')
                    setBarcodeOrd(null)
                    setPaginationModel({ ...paginationModel, page: 0 })
                    setFilterAO(filtroOrdiniAnonimoDefault)
                    setSituazione(situazioneColliDefault)
                    setDataArtAna(null)
                    handleRefresh()
                }}
                callbackClear={() => {
                    setBarcode('')
                    setBarcodeOrd(null)
                }}
            />
            <ArtAna
                onCancel={() => {
                    setBarcode('')
                    setBarcodeOrd(null)
                    setDataArtAna(null)
                }}
                dataArtAna={dataArtAna}
            />
            <RiepilogoBarcode
                onCancel={() => {
                    setBarcode('')
                    setBarcodeOrd(null)
                    setDataB(null)
                    setPaginationModel({ ...paginationModel, page: 0 })
                    handleRefresh()
                }}
                dataBarcode={dataB?.dataBarcode || []}
            />
            {stampaSelected != null && (
                <Stampe
                    colliConfez={stampaSelected}
                    callback={() => {
                        setStampaSelected(null)
                    }}
                />
            )}
            <Paper elevation={3} sx={{ mt: 1 }}>
                {immaginiSelected && (
                    <Immagini
                        onPreviewClick={(immaginiSelected) => {}}
                        id={immaginiSelected.PROGR_COLLO_ID}
                        DOC_ID={immaginiSelected.PROGR_COLLO_ID}
                        TIPO={TIPO_IMAGE.COLLI_CONFEZ}
                        cancel={() => {
                            setImmaginiSelected(null)
                        }}
                    />
                )}
                {colliConfezObjSelected && (
                    <ComboBoxSituazioneDialog
                        callback={(sit) => {
                            updateSituazione(sit)
                        }}
                        currentFilter={colliConfezObjSelected.SITUAZIONE_COLLO}
                        cancel={() => {
                            setColliConfezObjSelected(null)
                        }}
                    />
                )}
                <StripedDataGrid
                    pageSizeOptions={[5, 10, 25]}
                    disableRowSelectionOnClick
                    detailPanelExpandedRowIds={rowsIdsDetails}
                    getRowClassName={(params: GridRowClassNameParams<GridValidRowModel>) => {
                        if (rowsIdsDetails.length !== 0) {
                            if (params.row.PROGR_COLLO_ID === rowsIdsDetails[0]) {
                                return `super-app-theme-selected`
                            }
                        }
                        return ''
                    }}
                    onDetailPanelExpandedRowIdsChange={(ids: GridRowId[], details: GridCallbackDetails) => {
                        if (ids.length === 1) {
                            setRowsIdsDetails(ids)
                        } else if (ids.length > 1) {
                            setRowsIdsDetails([ids[1]])
                        } else {
                            setRowsIdsDetails([])
                        }
                    }}
                    onCellClick={(row) => {
                        if (row.colDef.field === columns[4].field) {
                            const colliConfezObj = rows?.find((it) => it.PROGR_COLLO_ID === row.id)
                            if (colliConfezObj != null) {
                                setColliConfezObjSelected(colliConfezObj)
                            }
                        }
                    }}
                    disableMultipleRowSelection={true}
                    getRowId={(row: GridValidRowModel) => {
                        return row.PROGR_COLLO_ID as string
                    }}
                    showCellVerticalBorder={false}
                    // DATI
                    loading={isFetching || isLoading}
                    rows={rows}
                    columns={columns}
                    // PAGINAZIONE
                    rowCount={rowsCount}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel,
                        },
                        sorting: {
                            sortModel: queryOptions.sortModel,
                        },
                        // filter: {
                        //     filterModel: queryOptions.filterModel,
                        // },
                    }}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={(paginationModel) => {
                        setPaginationModel(paginationModel)
                        handleRefresh()
                    }}
                    // FILTER
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    disableColumnFilter={true}
                    disableChildrenFiltering={true}
                    disableMultipleColumnsFiltering={true}
                    // ORDINAMENTO
                    onSortModelChange={handleSortModelChange}
                    disableMultipleColumnsSorting={true}
                    // CHILDREN
                    getDetailPanelContent={({ row }: any) => {
                        row = row as ColliConfezObjBarcode
                        if (dataB != null) {
                            return (
                                <ColliConfezRighe
                                    colliConfez={row}
                                    dataB={dataB}
                                    dataArtAna={dataArtAna}
                                    handleRefresh={handleRefresh}
                                />
                            )
                        }
                    }}
                    getDetailPanelHeight={({ row }) => 'auto'}
                    getRowHeight={() => 46}
                    autoHeight={true}
                    slots={{
                        toolbar: EditToolbar,
                    }}
                />
            </Paper>
        </>
    )
}
