import { Accordion, AccordionSummary, Button, Card, CardContent, Grid, Paper, Stack, Typography } from '@mui/material'
import 'moment/locale/it'

import { LabelDetail } from '../../components/LabelDetail'

import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone'
import FilterVintageTwoToneIcon from '@mui/icons-material/FilterVintageTwoTone'
import { SelectOption } from '../../components/GenericOption'
import { DataArtAnaObj } from '../colliConfez/colliConfezSlice'

interface ArtAnaProps {
    dataArtAna: DataArtAnaObj | null
    onCancel: () => void
}

export function ArtAna({ dataArtAna, onCancel }: ArtAnaProps) {
    if (dataArtAna == null) return <></>

    const defUmUtente = dataArtAna.defUmUtente
    const artUm = dataArtAna.artUm
    const artConf = dataArtAna.artConf
    const artAna = dataArtAna.artAna

    if (defUmUtente == null || defUmUtente.length === 0)
        return <Typography>Nessuna unità di misura di default impostata</Typography>
    if (artUm == null || artUm.length === 0) return <Typography>Nessuna unità di misura articolo impostata</Typography>
    if (artAna == null || artAna.length === 0) return <Typography>Nessun articolo trovato</Typography>

    const selectOptionArtUm: Array<SelectOption> =
        artUm.map((it) => {
            return { key: it.NUM_UM, value: `${it.DESC_UM} ${it.UM}` } as SelectOption
        }) || []

    const defaultSelectOptionArtUm: any = selectOptionArtUm.find(
        (it) => it.key === defUmUtente[0].NUM_UM && dataArtAna.defUmUtente[0].FLAG_UM_VEND === '1'
    )?.key

    const selectOptionArtConf: Array<SelectOption> =
        artConf.map((it) => {
            return { key: it.NUM_CONF, value: `${it.DESC_CONF} ${it.UM}` } as SelectOption
        }) || []

    const defaultSelectOptionArtConf: any = selectOptionArtConf.find((it) => it.key === defaultSelectOptionArtUm)?.key

    return (
        <>
            <Grid sx={{ mt: 0.5 }} spacing={1} container direction="row" alignItems="stretch">
                <Grid item xs={11}>
                    <Accordion variant="elevation" TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary>
                            <Stack direction={{ xs: 'column', sm: 'row' }}>
                                <FilterVintageTwoToneIcon sx={{ fontSize: '48px', mr: 2 }} />
                                <LabelDetail label={artAna[0].COD_ART} labelTitle="Cod.Art." />
                                <LabelDetail label={artAna[0].DES_ART} labelTitle="Desc.Art." />
                                <LabelDetail label={artAna[0].PESO_ART_NETTO} labelTitle="Peso netto" />
                            </Stack>
                        </AccordionSummary>
                    </Accordion>
                </Grid>
                <Grid item xs={1}>
                    <Button
                        fullWidth={true}
                        sx={{ height: '100%' }}
                        variant="contained"
                        onClick={() => {
                            onCancel()
                        }}
                    >
                        <CancelTwoToneIcon />
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}
