import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
    Typography
} from '@mui/material'
import { useCallback, useState } from 'react'

interface PropsNumericPad {
    currentValue?: string | null
    valueChanged: (value: string) => void
    closeDialog: () => void //event: React.MouseEvent
}

export const NumericPad = ({ currentValue, valueChanged, closeDialog }: PropsNumericPad) => {
    const [value, setValue] = useState<string>('') //(currentValue || "0")
    const [isValid, setIsValid] = useState(true)

    const [changeValue, setChangeValue] = useState(true)

    const handleClick = useCallback(
        (event: any) => {
            const text = event.target.textContent

            if (value != null) {
                switch (text) {
                    case '0':
                    case '1':
                    case '2':
                    case '3':
                    case '4':
                    case '5':
                    case '6':
                    case '7':
                    case '8':
                    case '9':
                        if (changeValue) {
                            setValue(text)
                            setChangeValue(false)
                            return
                        }
                        setValue((v) => {
                            if (v == '0') {
                                v = ''
                            }
                            const newVal = v + text
                            if (!newVal.match(/^[+-]?\d+(\.\d+)?$/)) {
                                setIsValid(false)
                                return v
                            }
                            setIsValid(true)
                            return newVal
                        })
                        break
                    case '.':
                        setValue((v) => {
                            setIsValid(false)
                            const newVal = v + text
                            if (newVal.split('.').length > 2) {
                                return v
                            }
                            return newVal
                        })
                        break
                    case 'Conferma':
                        if (value) {
                            valueChanged(value)
                        } else {
                            closeDialog()
                        }
                        break
                    case 'del':
                        setValue((v) => {
                            const newVal = v!.length > 0 ? v!.substring(0, v!.length - 1) : ''
                            if (!newVal.match(/^[+-]?\d+(\.\d+)?$/)) {
                                setIsValid(false)
                                return newVal
                            }
                            setIsValid(true)
                            return newVal
                        })
                        break
                }
            }
        },
        [value]
    )

    return (
        <Dialog maxWidth="xs" open={true}>
            <DialogTitle>Modifica quantità</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} margin={1} justifyContent="flex-end" alignItems="flex-start">
                            <Typography sx={{ fontSize: 9 }} variant="caption" color="textSecondary">
                                Q.tà
                            </Typography>
                            <Typography variant="h4" color={isValid ? 'textSecondary' : 'error'}>
                                {value || currentValue}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            sx={{ fontSize: '28px' }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            7
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            sx={{ fontSize: '28px' }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            8
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            sx={{ fontSize: '28px' }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            9
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            sx={{ fontSize: '28px' }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            4
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            sx={{ fontSize: '28px' }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            5
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            sx={{ fontSize: '28px' }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            6
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            sx={{ fontSize: '28px' }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            1
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            sx={{ fontSize: '28px' }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            2
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            sx={{ fontSize: '28px' }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            3
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            sx={{ fontSize: '28px' }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            .
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            sx={{ fontSize: '28px' }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            0
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            sx={{ fontSize: '28px' }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            del
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            sx={{ fontSize: '28px' }}
                            disabled={!isValid}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            Conferma
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeDialog()}>Annulla</Button>
            </DialogActions>
        </Dialog>
    )
}
