import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone'
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material'
import { SyntheticEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { useGetPrintersQuery } from './stampeAPI'
import { Printer, selectState, setTemplate, TEMPLATE_ID } from './stampeSlice'

export enum TEMPLATE_NAME {
    TEMPLATE_1 = '1. Etichetta progressivo bancale',
    TEMPLATE_2 = '2. Elenco articoli',
    TEMPLATE_3 = '3. Etichette articoli',
    TEMPLATE_4 = '3a. Etichette articoli BFT',
}

export function StampeImpostazioni() {
    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const { currentData: dataPrinters } = useGetPrintersQuery()

    const stateTemplateSelected = useAppSelector(selectState)

    const handleChangeTemplate = (event: SelectChangeEvent, templateId: TEMPLATE_ID) => {
        const printerSelected = dataPrinters?.data.find((a: Printer) => a.name?.toString() === event.target.value)

        if (printerSelected) {
            dispatch(setTemplate({ ...printerSelected, templateId }))
        }
    }

    const handleClickClienti = (event: SyntheticEvent, templateId: TEMPLATE_ID) => {
        navigate('/template4')
    }

    return (
        <>
            <Grid mt={3} container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Grid item>
                    <Typography variant="h6">Stampanti di default</Typography>
                </Grid>
            </Grid>
            <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="subtitle1">{TEMPLATE_NAME.TEMPLATE_1}</Typography>
                <Box sx={{ minWidth: 120, p: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel id="label-stampante-1">Stampante</InputLabel>
                        <Select
                            labelId="label-stampante-1"
                            value={String(
                                stateTemplateSelected.template1.name == null ? '' : stateTemplateSelected.template1.name
                            )}
                            label="Stampante"
                            onChange={(event) => {
                                handleChangeTemplate(event, TEMPLATE_ID.TEMPLATE_1)
                            }}
                        >
                            {dataPrinters?.data.map((item: Printer) => {
                                return (
                                    <MenuItem key={item.name} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Typography variant="subtitle1">{TEMPLATE_NAME.TEMPLATE_2}</Typography>
                <Box sx={{ minWidth: 120, p: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel id="label-stampante-2">Stampante</InputLabel>
                        <Select
                            labelId="label-stampante-2"
                            value={String(
                                stateTemplateSelected.template2.name == null ? '' : stateTemplateSelected.template2.name
                            )}
                            label="Stampante"
                            onChange={(event) => {
                                handleChangeTemplate(event, TEMPLATE_ID.TEMPLATE_2)
                            }}
                        >
                            {dataPrinters?.data.map((item: Printer) => {
                                return (
                                    <MenuItem key={item.name} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Typography variant="subtitle1">{TEMPLATE_NAME.TEMPLATE_3}</Typography>
                <Box sx={{ minWidth: 120, p: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel id="label-stampante-3">Stampante</InputLabel>
                        <Select
                            labelId="label-stampante-3"
                            value={String(
                                stateTemplateSelected.template3.name == null ? '' : stateTemplateSelected.template3.name
                            )}
                            label="Stampante"
                            onChange={(event) => {
                                handleChangeTemplate(event, TEMPLATE_ID.TEMPLATE_3)
                            }}
                        >
                            {dataPrinters?.data.map((item: Printer) => {
                                return (
                                    <MenuItem key={item.name} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Typography variant="subtitle1">{TEMPLATE_NAME.TEMPLATE_4}</Typography>
                <Box sx={{ minWidth: 120, p: 2 }}>
                    <Grid direction="row" justifyContent="center" alignItems="center" container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <Button
                                variant="contained"
                                endIcon={<PeopleAltTwoToneIcon />}
                                onClick={(event: SyntheticEvent) => handleClickClienti(event, TEMPLATE_ID.TEMPLATE_4)}
                            >
                                Clienti
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <FormControl fullWidth>
                                <InputLabel id="label-stampante-4">Stampante</InputLabel>
                                <Select
                                    labelId="label-stampante-4"
                                    value={String(
                                        stateTemplateSelected.template4.name == null
                                            ? ''
                                            : stateTemplateSelected.template4.name
                                    )}
                                    label="Stampante"
                                    onChange={(event) => {
                                        handleChangeTemplate(event, TEMPLATE_ID.TEMPLATE_4)
                                    }}
                                >
                                    {dataPrinters?.data.map((item: Printer) => {
                                        return (
                                            <MenuItem key={item.name} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </>
    )
}
