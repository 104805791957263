import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'
import InventoryTwoToneIcon from '@mui/icons-material/InventoryTwoTone'
import PhotoCameraTwoToneIcon from '@mui/icons-material/PhotoCameraTwoTone'
import PlaylistAddCircleTwoToneIcon from '@mui/icons-material/PlaylistAddCircleTwoTone'
import SyncTwoToneIcon from '@mui/icons-material/SyncTwoTone'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    styled,
    Theme,
    Typography,
} from '@mui/material'
import {
    DataGridPro,
    GridActionsCellItem,
    GridColDef,
    GridFilterModel,
    GridRenderCellParams,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    GridRowsProp,
    GridSortItem,
    GridSortModel,
    GridToolbarContainer,
    GridValidRowModel,
} from '@mui/x-data-grid-pro'
import Big from 'big.js'
import 'moment/locale/it'
import { useCallback, useEffect, useState } from 'react'
import {
    useDeleteColliConfezRigheMutation,
    useGetColliConfezRigheQuery,
    useInsertColliConfezRigheMutation,
    useUpdateColliConfezRigheMutation,
} from '../../app/api'
import { useAppSelector } from '../../app/hooks'
import { NumericPad } from '../../components/NumericPad'
import { snackActions } from '../../SnackbarUtilsConfigurator'
import {
    ArtUmObj,
    ColliConfezObj,
    ColliConfezObjBarcode,
    DataArtAnaObj,
    TIPO_BARCODE,
} from '../colliConfez/colliConfezSlice'
import { Immagini } from '../immagini/Immagini'
import { TIPO_IMAGE } from '../immagini/immaginiSlice'
import { selectTipiCodArtInterno } from '../impostazioni/impostazioniSlice'
import { ColliConfezRigheObj, ColliConfezRigheObjInsert, ColliConfezRigheObjUpdate } from './colliConfezRigheSlice'

const PAGE_SIZE = 10

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
}))

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
}

interface ColliConfezRigheProps {
    colliConfez: ColliConfezObj
    dataB: { dataBarcode: Array<ColliConfezObjBarcode>; dataArtAna: DataArtAnaObj } | null
    dataArtAna?: DataArtAnaObj | null
    handleRefresh: () => void
}

export function ColliConfezRighe({
    colliConfez,
    dataB,
    dataArtAna: dataArtAnaObj,
    handleRefresh,
}: ColliConfezRigheProps) {
    ;[...(document.querySelectorAll('div') as any)]
        .filter((el) => el.textContent.includes('Missing license key'))
        .reverse()[0]
        ?.remove()

    // nel caso in cui non è un articolo secco prendo dataArtAna dal barcode
    const dataArtAna = dataArtAnaObj == null ? dataB?.dataArtAna : dataArtAnaObj

    const tipiCodArtInterno = useAppSelector(selectTipiCodArtInterno)

    const [colliConfezRigheObjSelected, setColliConfezRigheObjSelected] = useState<ColliConfezRigheObj | null>(null)

    //COLUMNS DEFINITION and EVENTS edit save
    // INSERT UPDATE DELETE

    const [insertColliConfezRighe] = useInsertColliConfezRigheMutation()

    const calculateQtaVendita = (
        dataArtAna: DataArtAnaObj | null | undefined,
        colliConfezObjBarcode: ColliConfezObj,
        QUANT_RIGA: string
    ): ColliConfezObjBarcode | null => {
        // 1. cerca di recuperarlo dai COLLI_CONFEZ_BARCODE
        let artUm: ArtUmObj | undefined = dataArtAna?.artUm.find((it) => it.NUM_UM === colliConfezObjBarcode.NUM_UM)

        // 2. cerca di recuperarlo dai parametri d'impianto - caso in cui sto lavorando su articolo secco o collo orfano di ordine_cliente
        artUm = dataArtAna?.artUm.find(
            (it) => it.NUM_UM === dataArtAna?.defUmUtente[0].NUM_UM && dataArtAna.defUmUtente[0].FLAG_UM_VEND === '1'
        )

        // per ora le confezioni non vengono usate!!!
        // const artConf: ArtConfObj[] = dataArtAna.artConf.find((it) => it.CONF === artUm.CONF_UM)

        let ccoBarcode: ColliConfezObjBarcode = {
            ...colliConfezObjBarcode,
        }

        if (artUm != null) {
            ccoBarcode.CONVERS_UM_BASE = artUm.CONVERS_UM
            ccoBarcode.UM = artUm.UM
            ccoBarcode.NUM_UM = artUm.NUM_UM
        }

        if (ccoBarcode.CONVERS_UM_BASE == null) {
            snackActions.error('Unità di misura articolo non definita')
            return null
        } else {
            const CONVERS_UM_BASE = new Big(ccoBarcode.CONVERS_UM_BASE)
            const QUANT_RIGA_big = new Big(QUANT_RIGA)
            const QUANT_UM_BASE_big = CONVERS_UM_BASE.times(QUANT_RIGA_big)

            ccoBarcode.QUANT_RIGA = QUANT_RIGA
            ccoBarcode.QUANT_UM_BASE = QUANT_UM_BASE_big.toString()

            ccoBarcode.NUMERO_CONFEZIONI = '0'

            return ccoBarcode
        }
    }

    const handleInsert = async () => {
        try {
            if (dataB != null && dataB.dataBarcode.length > 0) {
                const colliConfezObjBarcode: ColliConfezObjBarcode | null = calculateQtaVendita(
                    dataArtAna,
                    dataB.dataBarcode[0],
                    '1'
                )

                debugger

                if (colliConfezObjBarcode != null) {
                    colliConfezObjBarcode.AREA_DECK = dataB.dataBarcode[0].AREA_DECK
                    colliConfezObjBarcode.PESO_NETTO = dataArtAna?.artAna[0].PESO_ART_NETTO
                    const cc: ColliConfezRigheObjInsert = {
                        colliConfez,
                        dataBarcode: colliConfezObjBarcode,
                    }

                    const response = await insertColliConfezRighe(cc).unwrap()

                    if (response.data.length > 0) {
                        snackActions.success(`Riga collo inserita : ${response.data[0].NUM_RIGA}`)
                        setColliConfezRigheObjSelected(response.data[0])
                        handleRefresh()
                    } else {
                        snackActions.error(response.message)
                    }
                }
            }
        } catch (error: any) {
            console.log(error)
            snackActions.error(error)
        }
    }

    const handleInsertArtAna = async () => {
        if (dataArtAna != null && dataArtAna.artAna.length > 0) {
            const row = colliConfez
            try {
                const ccObj: ColliConfezObjBarcode = {
                    PROGR_COLLO_ID: colliConfez.PROGR_COLLO_ID,
                    PROGR_COLLO_NUM_RIGA_ID: colliConfez.PROGR_COLLO_NUM_RIGA_ID,
                    //NUM_RIGA: null,
                    AZIENDA_ID: colliConfez.AZIENDA_ID,
                    //STAZIONE: null,
                    //DOC_ID: null,
                    //DOC_RIGA_ID: null,
                    COD_ART: dataArtAna.artAna[0].COD_ART,
                    //COD_VAR: dataArtAna.artAna.COD_VAR,
                    COD_ART_VAR: dataArtAna.artAna[0].COD_ART,
                    ////NUM_UM: dataArtAna.artUm.NUM_UM?.toString(),
                    ////UM: artUm.UM,
                    UM_BASE: dataArtAna.artAna[0].UM_BASE,
                    //QUANT_RIGA: dataArtAna.artAna.QUANT_RIGA,
                    //QUANT_UM_BASE: dataArtAna.artAna.QUANT_UM_BASE,
                    // UM_CONFEZIONE: dataArtAna.artAna.UM_CONFEZIONE,
                    // NUMERO_CONFEZIONI: dataArtAna.artAna.NUMERO_CONFEZIONI,
                    // CONT_1_CONFEZIONE: dataArtAna.artAna.CONT_1_CONFEZIONE,
                    // COD_LOT: dataArtAna.artAna.COD_LOT,
                    // MATRICOLA: dataArtAna.artAna.MATRICOLA,
                    // SOLO_MULTIPLI_CONFEZIONE: dataArtAna.artAna.SOLO_MULTIPLI_CONFEZIONE,
                    // FLAG_CONVERS_EDITABILE: dataArtAna.artAna.FLAG_CONVERS_EDITABILE,
                    ////CONVERS_UM_BASE: artUm.CONVERS_UM?.toString(), //dataArtAna.artAna.CONVERS_UM_BASE,
                    // DOCUM_RIGA_ID: artAna.DOCUM_RIGA_ID,
                    // DOC_TABLE: artAna.DOC_TABLE,
                    // DOC_EVAS_DOC_ID: artAna.DOC_EVAS_DOC_ID,
                    // DOC_EVAS_DOC_RIGA_ID: artAna.DOC_EVAS_DOC_RIGA_ID,
                    // MT_TRASVERSALI: artAna.MT_TRASVERSALI,
                    // COD_VAR_1: artAna.COD_VAR_1,
                    // COD_VAR_2: artAna.COD_VAR_2,
                    // COD_VAR_3: artAna.COD_VAR_3,
                    // DRI_CC: artAna.DRI_CC,
                    // DES_IMP_ART: artAna.DES_IMP_ART,
                    // ITEM: artAna.ITEM,
                    // AREA_DECK: artAna.AREA_DECK,
                    // SOLO_DECK: artAna.SOLO_DECK,
                    NR_RIGHE: '0',
                    NUM_RIGA: '',
                    PESO_NETTO: dataArtAna.artAna[0].PESO_ART_NETTO,
                }
                
                const colliConfezObjBarcode: ColliConfezObjBarcode | null = calculateQtaVendita(dataArtAna, ccObj, '1')

                if (colliConfezObjBarcode != null) {
                    const cc: ColliConfezRigheObjInsert = {
                        colliConfez,
                        dataBarcode: colliConfezObjBarcode,
                    }

                    const response = await insertColliConfezRighe(cc).unwrap()

                    if (response.data.length > 0) {
                        snackActions.success('Riga collo inserita : ' + response.data[0].NUM_RIGA)

                        setColliConfezRigheObjSelected(response.data[0])

                        handleRefresh()
                    } else {
                        snackActions.error(response.message)
                    }
                }
            } catch (error: any) {
                console.log(error)
                snackActions.error(error)
            }
        }
    }

    function EditToolbar(props: EditToolbarProps) {
        const { setRows, setRowModesModel } = props

        return (
            <GridToolbarContainer>
                {dataB != null && dataB.dataBarcode.length > 0 && dataB.dataBarcode[0].TIPO !== TIPO_BARCODE.COLLO && (
                    <Button sx={{ m: 1 }} variant="contained" onClick={handleInsert}>
                        <PlaylistAddCircleTwoToneIcon sx={{ mr: 2 }} fontSize="large" />
                        <InventoryTwoToneIcon />
                        {`${dataB?.dataBarcode[0].COD_ART} | ${dataB?.dataBarcode[0].DES_RIGA}`}
                    </Button>
                )}
                {dataArtAnaObj?.artAna != null && dataArtAnaObj?.artAna.length > 0 && (
                    <Button sx={{ m: 1 }} variant="contained" onClick={handleInsertArtAna}>
                        <PlaylistAddCircleTwoToneIcon sx={{ mr: 2 }} fontSize="large" />
                        <InventoryTwoToneIcon />
                        {`${dataArtAnaObj?.artAna[0].COD_ART} | ${dataArtAnaObj?.artAna[0].DES_ART}`}
                    </Button>
                )}
            </GridToolbarContainer>
        )
    }

    const [idDelete, setIdDelete] = useState<GridRowId | null>(null)

    const [deleteColliConfezRighe] = useDeleteColliConfezRigheMutation()

    const rowDelete = async () => {
        try {
            let colliConfezRigheObj = rows?.find((it) => it.PROGR_COLLO_NUM_RIGA_ID === idDelete)
            if (colliConfezRigheObj != null) {
                const ccr: ColliConfezRigheObj = {
                    PROGR_COLLO_ID: colliConfezRigheObj.PROGR_COLLO_ID,
                    PROGR_COLLO_NUM_RIGA_ID: colliConfezRigheObj.PROGR_COLLO_NUM_RIGA_ID,
                    NUM_RIGA: colliConfezRigheObj.NUM_RIGA,
                }
                const response = await deleteColliConfezRighe(ccr).unwrap()

                if (response.data.length > 0) {
                    snackActions.success(
                        'Riga collo rimossa : ' +
                            response.data[0].NUM_RIGA +
                            '   Cod.Art. : ' +
                            response.data[0].COD_ART
                    )

                    handleRefresh()
                } else {
                    snackActions.error(response.message)
                }

                setIdDelete(null)
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    const [updateColliConfezRighe] = useUpdateColliConfezRigheMutation()

    const rowUpdate = async (value: string) => {
        try {
            if (colliConfezRigheObjSelected != null) {
                const colliConfezRighe = calculateQtaVendita(dataArtAna, colliConfezRigheObjSelected, value)

                if (colliConfezRighe != null) {
                    const ccr: ColliConfezRigheObjUpdate = {
                        newRow: colliConfezRighe,
                        oldRow: colliConfezRigheObjSelected,
                        colliConfezObjBarcode: colliConfez,
                    }

                    const response = await updateColliConfezRighe(ccr).unwrap()

                    if (response.data.length > 0) {
                        snackActions.success(
                            'Riga collo aggiornata : ' +
                                response.data[0].NUM_RIGA +
                                '   Cod.Art. : ' +
                                response.data[0].COD_ART
                        )
                    } else {
                        snackActions.error(response.message)
                    }

                    setColliConfezRigheObjSelected(null)
                }
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    // PAGINATION

    interface GridSortFilterModel {
        sortModel: GridSortModel
        filterModel: GridFilterModel
    }

    const [queryOptions, setQueryOptions] = useState<GridSortFilterModel>({
        sortModel: [] as GridSortItem[],
        filterModel: {} as GridFilterModel,
    })

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions((qo) => {
            return { sortModel: [...sortModel], filterModel: queryOptions.filterModel }
        })
    }, [])

    const [rows, setRows] = useState<ColliConfezRigheObj[]>([])
    const [rowsCount, setRowsCount] = useState<number>(0)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(PAGE_SIZE)

    const { data, isLoading, isFetching, isError, isSuccess, refetch } = useGetColliConfezRigheQuery({
        gridSordModel: queryOptions?.sortModel,
        gridFilterModel: queryOptions?.filterModel,
        page,
        pageSize,
        row: colliConfez,
        tipiCodArtInterno,
    })

    useEffect(() => {
        setRows(data?.data || [])
        setRowsCount(data?.rowCount || 0)
    }, [data?.data])

    // FILTER

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        // Here you save the data you need from the filter model
        setQueryOptions((qo) => {
            return { filterModel: { ...filterModel }, sortModel: qo.sortModel }
        })
    }, [])

    function renderQta(params: GridRenderCellParams<any, any, string>) {
        return (
            <Button size="large" variant="contained" sx={{ padding: 0.25 }}>
                <Typography variant="h6">
                    {params.row.QUANT_RIGA} {params.row.UM}
                </Typography>
            </Button>
        )
    }

    function renderDesArt(params: GridRenderCellParams<any, any, string>) {
        return (
            <Typography variant="caption" style={{ wordBreak: 'break-all' }}>
                {params.row.COD_ART} <br /> {params.row.DES_RIGA}
            </Typography>
        )
    }

    function renderCodSecondario(params: GridRenderCellParams<any, any, string>) {
        return (
            <Typography variant="caption" style={{ wordBreak: 'break-all' }}>
                {params.row.ACI_COD_SECONDARIO_ART} - {params.row.ACI_DESCRIZIONE}
                <br />
                {params.row.COD_SECONDARIO_ART} - {params.row.DESCRIZIONE}
            </Typography>
        )
    }

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

    const getActions = (gridRow: GridRowParams<ColliConfezRigheObj>) => {
        const isInEditMode = rowModesModel[gridRow.id]?.mode === GridRowModes.Edit

        return [
            <GridActionsCellItem
                icon={<DeleteForeverTwoToneIcon />}
                label="Delete"
                onClick={() => {
                    setIdDelete(gridRow.id)
                }}
                color="inherit"
            />,
        ]
    }

    const [immaginiSelected, setImmaginiSelected] = useState<ColliConfezObj | null>(null)

    function renderImmagini(params: GridRenderCellParams<any, any, Number>) {
        const currentRow = params.row as ColliConfezObj
        return (
            <PhotoCameraTwoToneIcon
                onClick={() => {
                    setImmaginiSelected(currentRow)
                }}
            />
        )
    }

    const commonColProps: GridValidRowModel = {
        flex: 1,
        minWidth: 40,
        editable: false,
        type: 'string',
        hide: false,
        align: 'right',
        headerAlign: 'right',
    }

    const columns: Array<GridColDef> = [
        {
            field: 'NUM_RIGA',
            headerName: 'Riga',
            ...commonColProps,
            maxWidth: 55,
        },
        {
            field: 'COD_SECONDARIO_ART',
            headerName: 'Cod.Art./Desc.Art.',
            ...commonColProps,
            renderCell: renderDesArt,
        },
        {
            field: 'DES_RIGA',
            headerName: 'Desc.Riga/Desc.Secondario',
            ...commonColProps,
            renderCell: renderCodSecondario,
        },
        {
            field: 'QUANT_RIGA',
            headerName: '🖋 Qt.à',
            ...commonColProps,
            renderCell: renderQta,
            maxWidth: 80,
        },
        {
            field: '',
            headerName: '🖋',
            renderCell: renderImmagini,
            ...commonColProps,
            sortable: false,
            filterable: false,
            maxWidth: 40,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '🖋',
            sortable: false,
            editable: false,
            align: 'right',
            maxWidth: 40,
            getActions,
        },
    ]

    // DESIGN

    return (
        <>
            <Dialog maxWidth="xs" open={idDelete != null}>
                <DialogTitle>Elimina</DialogTitle>
                <DialogContent dividers>{`Premi SI per confermare`}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setIdDelete(null)
                        }}
                    >
                        No
                    </Button>
                    <Button
                        onClick={() => {
                            rowDelete()
                        }}
                    >
                        Si
                    </Button>
                </DialogActions>
            </Dialog>
            {immaginiSelected && (
                <Immagini
                    onPreviewClick={(immaginiSelected) => {}}
                    id={immaginiSelected.PROGR_COLLO_NUM_RIGA_ID}
                    DOC_ID={immaginiSelected.PROGR_COLLO_ID}
                    TIPO={TIPO_IMAGE.COLLI_CONFEZ_RIGHE}
                    cancel={() => {
                        setImmaginiSelected(null)
                    }}
                />
            )}
            {colliConfezRigheObjSelected != null && (
                <NumericPad
                    currentValue={colliConfezRigheObjSelected.QUANT_RIGA}
                    valueChanged={(value) => {
                        rowUpdate(value)
                    }}
                    closeDialog={() => {
                        setColliConfezRigheObjSelected(null)
                    }}
                />
            )}
            <Stack direction="row">
                <Button
                    sx={{ m: 0.2 }}
                    variant="contained"
                    onClick={() => {
                        refetch()
                    }}
                >
                    <SyncTwoToneIcon />
                </Button>
                <StripedDataGrid
                    pageSizeOptions={[5, 10, 25]}
                    disableRowSelectionOnClick
                    getRowHeight={() => 'auto'}
                    disableMultipleRowSelection={false}
                    onCellClick={(row) => {
                        if (row.colDef.field === 'QUANT_RIGA') {
                            const colliConfezRigheObj = rows?.find((it) => it.PROGR_COLLO_NUM_RIGA_ID === row.id)
                            if (colliConfezRigheObj != null) {
                                setColliConfezRigheObjSelected(colliConfezRigheObj)
                            }
                        }
                    }}
                    // ID
                    getRowId={(row: GridValidRowModel) => {
                        return row.PROGR_COLLO_NUM_RIGA_ID as string
                    }}
                    showCellVerticalBorder={false}
                    sx={(theme: Theme) => ({
                        m: 0.2,
                        width: '70%',
                    })}
                    // DATI
                    loading={isLoading || isFetching}
                    rows={rows || []}
                    columns={columns}
                    // PAGINAZIONE
                    rowCount={rowsCount}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize, page },
                        },
                    }}
                    paginationMode="server"
                    onPaginationModelChange={(paginationModel) => {
                        setPage(paginationModel.page)
                    }}
                    // FILTER
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    // ORDINAMENTO
                    onSortModelChange={handleSortModelChange}
                    disableMultipleColumnsSorting={true}
                    autoHeight={true}
                    // EDIT
                    rowModesModel={rowModesModel}
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    slotProps={{
                        toolbar: { setRows, setRowModesModel },
                    }}
                />
            </Stack>
        </>
    )
}
