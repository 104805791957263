import {
    Box,
    Divider,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
} from '@mui/material'
import 'moment/locale/it'
import { SITUAZIONE_TYPE } from './colliConfezSlice'

import ApprovalTwoToneIcon from '@mui/icons-material/ApprovalTwoTone'
import CancelScheduleSendTwoToneIcon from '@mui/icons-material/CancelScheduleSendTwoTone'
import DoDisturbTwoToneIcon from '@mui/icons-material/DoDisturbTwoTone'
import FlightTakeoffTwoToneIcon from '@mui/icons-material/FlightTakeoffTwoTone'
import InsertLinkTwoToneIcon from '@mui/icons-material/InsertLinkTwoTone'
import LinkOffTwoToneIcon from '@mui/icons-material/LinkOffTwoTone'
import AppsTwoToneIcon from '@mui/icons-material/AppsTwoTone'
interface Props {
    situazione: SITUAZIONE_TYPE
    callback: (value: SITUAZIONE_TYPE) => void
}

export const SITUAZIONI = [
    {
        value: SITUAZIONE_TYPE.TUTTI,
        label: 'Tutti',
        icon: <AppsTwoToneIcon color="info" />,
    },
    {
        value: SITUAZIONE_TYPE.PRONTO_PER_LA_SPEDIZIONE,
        label: 'Pronto per la spedizione',
        icon: <ApprovalTwoToneIcon color="success" />,
    },
    {
        value: SITUAZIONE_TYPE.CHIUSO_E_ASSEGNATO,
        label: 'Chiuso e assegnato',
        icon: <InsertLinkTwoToneIcon color="info" />,
    },
    {
        value: SITUAZIONE_TYPE.CHIUSO_E_NON_ASSEGNATO,
        label: 'Chiuso e non assegnato',
        icon: <LinkOffTwoToneIcon color="disabled" />,
    },
    {
        value: SITUAZIONE_TYPE.IN_CONFEZIONAMENTO,
        label: 'In confezionamento',
        icon: <LinearProgress sx={{ width: '24px' }} color="primary" />,
    },
    { value: SITUAZIONE_TYPE.SOSPESO, label: 'Sospeso', icon: <CancelScheduleSendTwoToneIcon color="warning" /> },
    { value: SITUAZIONE_TYPE.SPEDITO, label: 'Spedito', icon: <FlightTakeoffTwoToneIcon color="primary" /> },
    { value: SITUAZIONE_TYPE.ANNULLATO, label: 'Annullato', icon: <DoDisturbTwoToneIcon color="error" /> },
]

export function ComboBoxSituazione({ callback, situazione }: Props) {
    const handleChangeSituazione = (event: SelectChangeEvent) => {
        callback(event.target.value as SITUAZIONE_TYPE)
    }

    return (
        <FormControl sx={{ m: 1 }} size="small">
            {<InputLabel id="label-situazione">Situazione colli</InputLabel>}
            <Select value={situazione} label={'Situazione colli'} onChange={handleChangeSituazione}>
                {SITUAZIONI.map(({ value, icon, label }: any) => {
                    return (
                        <MenuItem key={value} value={value}>
                            <Stack spacing={1} direction="row" divider={<Divider orientation="vertical" />}>
                                {icon} {label}
                            </Stack>
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}
