import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone'
import SyncTwoToneIcon from '@mui/icons-material/SyncTwoTone'
import { alpha, Button, Divider, Paper, styled, Theme, Typography } from '@mui/material'
import { gridClasses } from '@mui/x-data-grid'
import {
    DataGridPro,
    GridColDef,
    GridFilterModel,
    GridRenderCellParams,
    GridRowModesModel,
    GridRowsProp,
    GridSortItem,
    GridSortModel,
    GridToolbarContainer,
    GridValidRowModel,
} from '@mui/x-data-grid-pro'
import 'moment/locale/it'
import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { ColliConfezObj } from '../colliConfez/colliConfezSlice'
import { selectAzienda } from '../impostazioni/impostazioniSlice'
import { useInsertOrUpdateTemplateReportStampaMutation, usePostMutation } from './clientiTemplateAPI'
import { CfCliTemplateReportStampa, selectState } from './clientiTemplateSlice'
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone'
import { TEMPLATE_ID } from '../stampe/stampeSlice'
const ODD_OPACITY = 0.2

const PAGE_SIZE = 50

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity
                    ),
                },
            },
        },
    },
}))

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
}

interface Props {
    template: TEMPLATE_ID
}

export function ClientiTemplate({ template }: Props) {
    ;[...(document.querySelectorAll('div') as any)]
        .filter((el) => el.textContent.includes('Missing license key'))
        .reverse()[0]
        ?.remove()

    const dispatch = useAppDispatch()

    const aziendaSelected = useAppSelector(selectAzienda)

    const [addPost, { isLoading, isError }] = usePostMutation()

    const stateTemplateSelected = useAppSelector(selectState)

    const [rows, setRows] = useState<ColliConfezObj[]>([])
    const [rowsCount, setRowsCount] = useState<number>(0)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(PAGE_SIZE)
    const [loading, setLoading] = useState(isLoading)
    const [refresh, setRefresh] = useState<number>(0)

    const [insertPost, { isSuccess: isSuccessInsertOrUpdate }] = useInsertOrUpdateTemplateReportStampaMutation()

    const [queryOptions, setQueryOptions] = useState<GridSortFilterModel>({
        sortModel: [] as GridSortItem[],
        filterModel: {} as GridFilterModel,
    })

    useEffect(() => {
        const exect = async () => {
            const result = await addPost({
                gridSordModel: queryOptions?.sortModel,
                gridFilterModel: queryOptions?.filterModel,
                page,
                pageSize,
                AZIENDA_ID: aziendaSelected.AZIENDA_ID,
                template,
            }).unwrap()
            setRows(result.data)
            setRowsCount(result.rowCount)
        }
        exect()
    }, [queryOptions, page, pageSize, refresh, isSuccessInsertOrUpdate])

    // PAGINATION

    interface GridSortFilterModel {
        sortModel: GridSortModel
        filterModel: GridFilterModel
    }

    const handleRefresh = async () => {
        setRefresh((ref) => {
            return ref + 1
        })
    }

    function renderTemplate(params: GridRenderCellParams<Number>) {
        const currentRow = params.row as CfCliTemplateReportStampa
        if (currentRow.TEMPLATE?.toString() === TEMPLATE_ID.TEMPLATE_4.toString()) {
            return (
                <CheckBoxTwoToneIcon
                    onClick={() => {
                        //setImmaginiSelected(currentRow)
                        insertPost({ ...currentRow, TEMPLATE: TEMPLATE_ID.TEMPLATE_0 })
                    }}
                />
            )
        } else {
            return (
                <CheckBoxOutlineBlankTwoToneIcon
                    onClick={() => {
                        //setImmaginiSelected(currentRow)
                        insertPost({ ...currentRow, TEMPLATE: TEMPLATE_ID.TEMPLATE_4 })
                    }}
                />
            )
        }
    }

    const commonColProps: GridValidRowModel = {
        flex: 1,
        minWidth: 40,
        editable: false,
        type: 'string',
        hide: false,
        align: 'right',
        headerAlign: 'right',
        filterable: true,
        sortable: true,
    }

    const columns: Array<GridColDef> = [
        {
            field: 'TEMPLATE',
            headerName: 'Template',
            ...commonColProps,
            minWidth: 80,
            maxWidth: 80,
            renderCell: renderTemplate,
        },
        {
            field: 'COD_CF',
            headerName: 'Codice cliente',
            ...commonColProps,
            filterable: true,
            sortable: true,
            minWidth: 200,
            maxWidth: 200,
        },
        {
            field: 'RAG_SOC_CF',
            headerName: 'Ragione sociale',
            filterable: true,
            ...commonColProps,
            minWidth: 200,
        },
    ]

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        // Here you save the data you need from the filter model
        setQueryOptions((qo) => {
            return { filterModel: { ...filterModel }, sortModel: qo.sortModel }
        })
    }, [])

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions((qo) => {
            return { sortModel: [...sortModel], filterModel: queryOptions.filterModel }
        })
    }, [])

    function EditToolbar(props: EditToolbarProps) {
        return (
            <GridToolbarContainer>
                <Typography sx={{ ml: 3, mr: 3 }} variant="h4">
                    Clienti con Report Stampa BFT
                </Typography>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Button sx={{ m: 1 }} variant="contained" startIcon={<SyncTwoToneIcon />} onClick={handleRefresh}>
                    RICARICA
                </Button>
            </GridToolbarContainer>
        )
    }

    return (
        <>
            <Paper
                elevation={3}
                sx={{ mt: 1 }}
                style={{
                    background: 'linear-gradient(0deg, rgba(255,160,0,0) 0%, rgba(101,69,0,0.04805672268907568) 100%)',
                }}
            >
                <StripedDataGrid
                    pageSizeOptions={[5, 10, 25]}
                    // onCellClick={(row) => {
                    //     if (row.colDef.field === 'SITUAZIONE_COLLO') {
                    //         const colliConfezObj = rows?.find((it) => it.PROGR_COLLO_ID === row.id)
                    //         if (colliConfezObj != null) {
                    //             setColliConfezObjSelected(colliConfezObj)
                    //         }
                    //     }
                    // }}
                    disableMultipleRowSelection={true}
                    getRowId={(row: GridValidRowModel) => {
                        return row.COD_CF as string
                    }}
                    sx={(theme: Theme) => ({
                        //backgroundColor: gridColor,
                        // backgroundColor:
                        //     theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.04)',
                        // '& .MuiDataGrid-row:nth-child(even):not(:hover)': {
                        //     backgroundColor:
                        //         theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.06)' : 'rgba(255, 255, 255, 0.06)',
                        // },
                    })}
                    // DATI
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    // PAGINAZIONE
                    rowCount={rowsCount}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize, page },
                        },
                    }}
                    paginationMode="server"
                    onPaginationModelChange={(paginationModel) => {
                        setPage(paginationModel.page)
                    }}
                    // FILTER
                    onFilterModelChange={onFilterChange}
                    // ORDINAMENTO
                    onSortModelChange={handleSortModelChange}
                    disableMultipleColumnsSorting={true}
                    //getDetailPanelHeight={({ row }) => 600}
                    getRowHeight={() => 46}
                    autoHeight={true}
                    // EDIT
                    // editMode="row"
                    // rowModesModel={rowModesModel}
                    // onRowEditStart={handleRowEditStart}
                    // onRowEditStop={handleRowEditStop}
                    // processRowUpdate={processRowUpdate}
                    // onProcessRowUpdateError={handleProcessRowUpdateError}
                    components={{
                        Toolbar: EditToolbar,
                    }}
                />
            </Paper>
        </>
    )
}
