import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material'
import 'moment/locale/it'
import { SITUAZIONE_TYPE } from './colliConfezSlice'
import { SITUAZIONI } from './ComboBoxSituazione'

interface Props {
    callback: (value: SITUAZIONE_TYPE) => void
    cancel: () => void
    currentFilter?: SITUAZIONE_TYPE
}

export function ComboBoxSituazioneDialog({ callback, cancel, currentFilter }: Props) {
    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        situazioni_colli: SITUAZIONE_TYPE
    ) => {
        callback(situazioni_colli)
    }



    return (
        <Dialog maxWidth="xs" open={true}>
            <DialogTitle>Modifica situazione collo</DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <List component="nav">
                        {SITUAZIONI.filter((it) => it.value !== SITUAZIONE_TYPE.TUTTI)
                            .filter((it) => it.value !== currentFilter)
                            .map(
                                (
                                    { value, label, icon }: { value: SITUAZIONE_TYPE; label: string; icon: any },
                                    index: number
                                ) => {
                                    return (
                                        <ListItemButton
                                            key={value}
                                            onClick={(event) => handleListItemClick(event, value)}
                                        >
                                            <ListItemIcon>{icon}</ListItemIcon>
                                            <ListItemText primary={label} />
                                        </ListItemButton>
                                    )
                                }
                            )}
                    </List>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        cancel()
                    }}
                >
                    No
                </Button>
            </DialogActions>
        </Dialog>
    )
}
