import { ThemeProvider } from '@emotion/react'
import Brightness4TwoToneIcon from '@mui/icons-material/Brightness4TwoTone'
import { Box, createTheme, CssBaseline, FormControlLabel, Switch, ThemeOptions } from '@mui/material'
import { deepPurple } from '@mui/material/colors'
import { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Footer } from './components/Footer'
import { ColliConfez } from './features/colliConfez/ColliConfez'
import { Impostazioni } from './features/impostazioni/Impostazioni'
import { Login } from './features/login/Login'
import PrivateRoute from './features/login/PrivateRoute'
import { ClientiTemplate } from './features/clientiTemplate/ClientiTemplate'
import ResponsiveDrawer from './ResponsiveDrawer'
import { TEMPLATE_ID } from './features/stampe/stampeSlice'
import { Immagini } from './features/immagini/Immagini'

// Define theme settings
const light: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#3290D7',
        },
        secondary: {
            main: '#FBC23E',
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 10,
    },
}

const dark: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#4546BC',
        },
        secondary: {
            main: '#E75D29',
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 10,
    },
}

function App() {
    // The light theme is used by default
    const [isDarkTheme, setIsDarkTheme] = useState(false)

    const changeTheme = () => {
        setIsDarkTheme(!isDarkTheme)
    }

    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
            setIsDarkTheme(e.matches)
        })
    }, [])

    const theme = isDarkTheme ? createTheme(dark) : createTheme(light)

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <ResponsiveDrawer
                        ToggleTheme={
                            <FormControlLabel
                                control={
                                    <>
                                        <Switch checked={isDarkTheme} onChange={changeTheme} />
                                        <Brightness4TwoToneIcon />
                                    </>
                                }
                                label=""
                                labelPlacement="start"
                                sx={{ flexGrow: 1, mr: 2 }}
                            />
                        }
                    >
                        <Box sx={{
                            pt: theme.spacing(8),
                            pl: theme.spacing(1),
                            pr: theme.spacing(1),
                            pb: theme.spacing(10),
                        }} id="back-to-top-anchor">
                            <Routes>
                                <Route path="/login" element={<Login />} />
                                <Route
                                    path="/"
                                    element={
                                        <PrivateRoute>
                                            <ColliConfez gridColor={deepPurple[50]}></ColliConfez>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/impostazioni"
                                    element={
                                        <PrivateRoute>
                                            <Impostazioni gridColor={deepPurple[50]}></Impostazioni>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/template4"
                                    element={
                                        <PrivateRoute>
                                            <ClientiTemplate template={TEMPLATE_ID.TEMPLATE_4} />
                                        </PrivateRoute>
                                    }
                                />
                                <Route path="*" element={<p>404</p>} />
                            </Routes>
                        </Box>
                    </ResponsiveDrawer>
                    {/* <Footer /> */}
                </BrowserRouter>
            </ThemeProvider>
        </>
    )
}

export default App
