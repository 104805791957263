import PreviewTwoToneIcon from '@mui/icons-material/PreviewTwoTone'
import PrintTwoToneIcon from '@mui/icons-material/PrintTwoTone'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from '@mui/material'
import { SyntheticEvent, useState } from 'react'
import { useAppSelector } from '../../app/hooks'
import { ColliConfezObj } from '../colliConfez/colliConfezSlice'
import { selectTipiCodArtInterno } from '../impostazioni/impostazioniSlice'
import { TEMPLATE_NAME } from './StampeImpostazioni'
import { usePostTemplateReportStampaMutation } from './stampeAPI'
import { Printer, TEMPLATE_ID, selectState } from './stampeSlice'
interface StampeProps {
    colliConfez: ColliConfezObj
    callback: () => void
}

export function Stampe({ colliConfez, callback }: StampeProps) {
    const stampeTemplate = useAppSelector(selectState)

    let template: Printer = stampeTemplate.template3
    if (colliConfez?.TEMPLATE != null && Number(colliConfez?.TEMPLATE) === TEMPLATE_ID.TEMPLATE_4) {
        template = stampeTemplate.template4
    }

    const [stampaPost, { isSuccess: isSuccessStampaPost }] = usePostTemplateReportStampaMutation()

    const [pdfImagePreviewId, setPdfImagePreviewId] = useState<string | null>(null)

    const tipiCodArtInterno = useAppSelector(selectTipiCodArtInterno)

    const handleClickStampa = async (event: SyntheticEvent, templateId: TEMPLATE_ID, isPreview: boolean = true) => {
        let template: Printer | null = null

        switch (templateId) {
            case TEMPLATE_ID.TEMPLATE_1:
                template = stampeTemplate.template1
                break
            case TEMPLATE_ID.TEMPLATE_2:
                template = stampeTemplate.template2
                break
            case TEMPLATE_ID.TEMPLATE_3:
                template = stampeTemplate.template3
                break
            case TEMPLATE_ID.TEMPLATE_4:
                template = stampeTemplate.template4
                break
        }

        const resp = await stampaPost({
            template,
            colliConfez,
            tipiCodArtInterno,
            isPreview,
        }).unwrap()

        setPdfImagePreviewId(resp.fileName)
    }

    return (
        <>
            <Dialog maxWidth="md" open={true}>
                <DialogTitle>Scegli il report di stampa</DialogTitle>
                <DialogContent dividers>
                    <Grid container rowSpacing={3} direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={(event) => {
                                        handleClickStampa(event, TEMPLATE_ID.TEMPLATE_1)
                                    }}
                                    startIcon={<PreviewTwoToneIcon />}
                                >
                                    {TEMPLATE_NAME.TEMPLATE_1}
                                </Button>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={(event) => {
                                        handleClickStampa(event, TEMPLATE_ID.TEMPLATE_1, false)
                                    }}
                                    startIcon={<PrintTwoToneIcon />}
                                >
                                    {TEMPLATE_NAME.TEMPLATE_1}
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={(event) => {
                                        handleClickStampa(event, TEMPLATE_ID.TEMPLATE_2)
                                    }}
                                    startIcon={<PreviewTwoToneIcon />}
                                >
                                    {TEMPLATE_NAME.TEMPLATE_2}
                                </Button>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={(event) => {
                                        handleClickStampa(event, TEMPLATE_ID.TEMPLATE_2, false)
                                    }}
                                    startIcon={<PrintTwoToneIcon />}
                                >
                                    {TEMPLATE_NAME.TEMPLATE_2}
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <Typography align="center" variant="caption">
                                    {tipiCodArtInterno.COD_TIPO_COD} - {tipiCodArtInterno.DES_TIPO_COD}
                                </Typography>
                            </Box>
                            <Stack direction="row" spacing={2}>
                                {template?.templateId === TEMPLATE_ID.TEMPLATE_3 && (
                                    <>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={(event) => {
                                                handleClickStampa(event, TEMPLATE_ID.TEMPLATE_3)
                                            }}
                                            startIcon={<PreviewTwoToneIcon />}
                                        >
                                            {TEMPLATE_NAME.TEMPLATE_3}
                                        </Button>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={(event) => {
                                                handleClickStampa(event, TEMPLATE_ID.TEMPLATE_3, false)
                                            }}
                                            startIcon={<PrintTwoToneIcon />}
                                        >
                                            {TEMPLATE_NAME.TEMPLATE_3}
                                        </Button>
                                    </>
                                )}
                                {template?.templateId === TEMPLATE_ID.TEMPLATE_4 && (
                                    <>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={(event) => {
                                                handleClickStampa(event, TEMPLATE_ID.TEMPLATE_4)
                                            }}
                                            startIcon={<PreviewTwoToneIcon />}
                                        >
                                            {TEMPLATE_NAME.TEMPLATE_4}
                                        </Button>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={(event) => {
                                                handleClickStampa(event, TEMPLATE_ID.TEMPLATE_4, false)
                                            }}
                                            startIcon={<PrintTwoToneIcon />}
                                        >
                                            {TEMPLATE_NAME.TEMPLATE_4}
                                        </Button>
                                    </>
                                )}
                            </Stack>
                        </Grid>
                        {pdfImagePreviewId != null && (
                            <>
                                <Grid item xs={12}>
                                    <img
                                        style={{ width: '100%', height: 'auto' }}
                                        src={`${process.env.REACT_APP_ENDPOINT}/stampe/getImage?uuidImage=${pdfImagePreviewId}`}
                                        alt={pdfImagePreviewId}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            callback()
                        }}
                    >
                        Esci
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
