import CloseIcon from '@mui/icons-material/Close'
import UploadFileTwoToneIcon from '@mui/icons-material/UploadFileTwoTone'
import { AppBar, Button, IconButton, Toolbar, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useFilePicker } from 'use-file-picker'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
    AziendaObj,
    selectAzienda,
    selectTipiImageDocColliConfez,
    selectTipiImageDocColliConfezRighe,
    TipiImageDoc,
} from '../impostazioni/impostazioniSlice'
import { ImmaginiProps } from './Immagini'
import { uploadImage } from './immaginiAPI'
import { addImage, resetImage, ImmagineRequest, TIPO_IMAGE } from './immaginiSlice'

export function ImmaginiAppBar({ TIPO, id, DOC_ID, cancel }: ImmaginiProps) {
    const aziendaSelected: AziendaObj = useAppSelector(selectAzienda)
    const tipiImageDocColliConfez: TipiImageDoc = useAppSelector(selectTipiImageDocColliConfez)
    const tipiImageDocColliConfezRighe: TipiImageDoc = useAppSelector(selectTipiImageDocColliConfezRighe)

    let COD_TIPO_IMAGE_DOC: string = ''
    let DES_TIPO_IMAGE_DOC: string | undefined
    switch (TIPO) {
        case TIPO_IMAGE.COLLI_CONFEZ:
            COD_TIPO_IMAGE_DOC = tipiImageDocColliConfez.COD_TIPO_IMAGE_DOC
            DES_TIPO_IMAGE_DOC = tipiImageDocColliConfez.DES_TIPO_IMAGE_DOC
            break
        case TIPO_IMAGE.COLLI_CONFEZ_RIGHE:
            COD_TIPO_IMAGE_DOC = tipiImageDocColliConfezRighe.COD_TIPO_IMAGE_DOC
            DES_TIPO_IMAGE_DOC = tipiImageDocColliConfezRighe.DES_TIPO_IMAGE_DOC
            break
    }

    const [openFileSelector, { filesContent, plainFiles, loading, clear }] = useFilePicker({
        readAs: 'DataURL',
        accept: ['image/*'],
        multiple: false,
    })

    useEffect(() => {
        if (filesContent.length > 0) {
            asyncFn(filesContent[0].content)
        }

        return () => {
            clear()
        }
    }, [filesContent.length])

    const dispatch = useAppDispatch()

    async function asyncFn(IMMAGINE: any) {
        if (loading === false) {
            const AZIENDA_ID = aziendaSelected.AZIENDA_ID

            uploadImage(id, DOC_ID, TIPO, AZIENDA_ID, COD_TIPO_IMAGE_DOC, IMMAGINE, (image: ImmagineRequest) => {
                dispatch(addImage(image))
            })
        }
    }

    return (
        <AppBar component="nav">
            <Toolbar variant="dense">
                <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
                    {/* {isLoadingInsert && "Caricamento in corso..."} */}
                    {/* {data != null && data.images.length > 0 && data.images[activeStep].id} */}
                    {id}
                </Typography>
                <Typography variant="caption" sx={{ mr: 2 }}>
                    ({COD_TIPO_IMAGE_DOC} - {DES_TIPO_IMAGE_DOC})
                </Typography>
                <Button
                    sx={{ mr: 1 }}
                    variant="contained"
                    onClick={() => {
                        openFileSelector()
                    }}
                >
                    <UploadFileTwoToneIcon />
                </Button>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={(event) => {
                        dispatch(resetImage())
                        if (cancel) cancel(event)
                    }}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}
