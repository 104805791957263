import { Button } from '@mui/material'
import React from 'react'
import Webcam from 'react-webcam'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import useWindowDimensions from '../../components/WindowDimesion'
import {
    AziendaObj,
    selectAzienda,
    selectTipiImageDocColliConfez,
    selectTipiImageDocColliConfezRighe,
    TipiImageDoc,
} from '../impostazioni/impostazioniSlice'
import { ImmaginiProps } from './Immagini'
import { uploadImage } from './immaginiAPI'
import { addImage, ImmagineRequest, selectCameraImmaginiDefault, TIPO_IMAGE } from './immaginiSlice'

export function ImmaginiCamera({ TIPO, id, DOC_ID }: ImmaginiProps) {
    const { height, width } = useWindowDimensions()

    const cameraDefault = useAppSelector(selectCameraImmaginiDefault)

    // CAMERA METHODS

    const aziendaSelected: AziendaObj = useAppSelector(selectAzienda)
    const tipiImageDocColliConfez: TipiImageDoc = useAppSelector(selectTipiImageDocColliConfez)
    const tipiImageDocColliConfezRighe: TipiImageDoc = useAppSelector(selectTipiImageDocColliConfezRighe)

    const dispatch = useAppDispatch()

    let COD_TIPO_IMAGE_DOC: string = ''
    let DES_TIPO_IMAGE_DOC: string | undefined
    switch (TIPO) {
        case TIPO_IMAGE.COLLI_CONFEZ:
            COD_TIPO_IMAGE_DOC = tipiImageDocColliConfez.COD_TIPO_IMAGE_DOC
            DES_TIPO_IMAGE_DOC = tipiImageDocColliConfez.DES_TIPO_IMAGE_DOC
            break
        case TIPO_IMAGE.COLLI_CONFEZ_RIGHE:
            COD_TIPO_IMAGE_DOC = tipiImageDocColliConfezRighe.COD_TIPO_IMAGE_DOC
            DES_TIPO_IMAGE_DOC = tipiImageDocColliConfezRighe.DES_TIPO_IMAGE_DOC
            break
    }

    const videoConstraints = {
        width: 1280,
        height: 720,
        deviceId: cameraDefault.deviceId,
    }

    const webcamRef = React.useRef<any>(null)
    const capture = React.useCallback(() => {
        const IMMAGINE = webcamRef.current?.getScreenshot()

        const AZIENDA_ID = aziendaSelected.AZIENDA_ID

        uploadImage(id, DOC_ID, TIPO, AZIENDA_ID, COD_TIPO_IMAGE_DOC, IMMAGINE, (image: ImmagineRequest) => {
            dispatch(addImage(image))
        })
    }, [webcamRef])

    return (
        <div style={{ width: '100%', height: '88%', textAlign: 'center', marginTop: '3%' }}>
            <Webcam
                style={{ width: '100%', height: '88%' }}
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                screenshotQuality={1}
                imageSmoothing={true}
            />
            <Button variant="contained" onClick={capture} style={{ position: 'relative', bottom: '12%', zIndex: 2 }}>
                Take photo!
            </Button>
            {/* <Button
                variant="contained"
                onClick={capture}
                style={{ position: 'relative', bottom: '12%', left: '10%', zIndex: 2 }}
            >
                Flash
            </Button> */}
        </div>
    )
}
