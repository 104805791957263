import { Stack, Typography } from '@mui/material'

export interface Props {
    labelTitle?: string | null
    label?: string | null
}

export const LabelDetail = ({ labelTitle, label }: Props) => {
    return (
        <>
            {label != null && label.length > 0 && (
                <>
                    <Stack spacing={0.5} margin={0.5}>
                        <Typography sx={{ fontSize: 9 }} variant="caption" color="textSecondary">
                            {labelTitle}
                        </Typography>
                        <Typography variant="body1" color="ButtonText">
                            {label}
                        </Typography>
                    </Stack>
                </>
            )}
        </>
    )
}
