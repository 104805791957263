import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { TEMPLATE_ID } from '../stampe/stampeSlice'

const initialState = {}

export interface DataGridCfCliTemplateReportStampa {
    gridSordModel?: GridSortModel
    gridFilterModel?: GridFilterModel
    page: number
    pageSize: number

    AZIENDA_ID: number
    template: TEMPLATE_ID
}

export interface CfCliTemplateReportStampa {
    AZIENDA_ID?: number
    COD_CF?: string
    RAG_SOC_CF?: string
    TEMPLATE?: TEMPLATE_ID
}

// // The function below is called a thunk and allows us to perform async logic. It
// // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// // will call the thunk with the `dispatch` function as the first argument. Async
// // code can then be executed and other actions can be dispatched. Thunks are
// // typically used to make async requests.
// export const incrementAsync = createAsyncThunk('login/fetchCount', async (amount: number) => {
//     const response = await fetchCount(amount)
//     // The value we return becomes the `fulfilled` action payload
//     return response.data
// })

export const clientiTemplateSlice = createSlice({
    name: 'clientiTemplate',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setTemplate: (state, action: PayloadAction<any>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
        },
        // decrement: (state) => {
        //     state.value -= 1
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload
        // },
    },
    // extraReducers: (builder) => {
    //     builder.addMatcher(valoriApi.endpoints.post.matchFulfilled, (state, { payload, type, meta }) => {
    //         console.log(payload)

    //         state[meta.arg.originalArgs.type] = payload
    //     })
    // },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(incrementAsync.pending, (state) => {
    //             state.status = 'loading'
    //         })
    //         .addCase(incrementAsync.fulfilled, (state, action) => {
    //             state.status = 'idle'
    //             state.value += action.payload
    //         })
    //         .addCase(incrementAsync.rejected, (state) => {
    //             state.status = 'failed'
    //         })
    // },
})

export const { setTemplate } = clientiTemplateSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.login.value)`
export const selectState = (state: RootState) => state.clientiTemplate

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const setAziendaStored =
//     (azienda: AziendaObj): AppThunk =>
//     (dispatch, getState) => {
//         const aziendaSelected = selectAzienda(getState())
//         console.log(aziendaSelected)
//         dispatch(setAzienda(azienda))
//     }

export default clientiTemplateSlice.reducer
