import BackspaceTwoToneIcon from '@mui/icons-material/BackspaceTwoTone'
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import { Box, Button, Divider, IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../app/hooks'
import { selectBarcodeSelector } from '../impostazioni/impostazioniSlice'
import { BarcodeVideo } from './BarcodeVideo'

interface Props {
    callbackValue(value: string): void
    callbackCancel(): void
    callbackClear(): void
    title: string
    prevBarcode: string
}

export function Barcode({ callbackValue, callbackCancel, callbackClear, title, prevBarcode }: Props) {
    const textRef = useRef<TextFieldProps>()

    useEffect(() => {
        if (prevBarcode != null) {
            textRef.current!.value! = prevBarcode
        }
    }, [prevBarcode])

    const stateSelected = useAppSelector(selectBarcodeSelector)

    //const [valore, setValore] = useState<Array<String>>([])

    let prev = ''
    let next = false
    function handleKeydown(event: any) {
        console.log(event)

        if (event.defaultPrevented) {
            return // Do nothing if the event was already processed
        }

        if (event?.target?.type != undefined) {
            // se sono in
            return
        }

        switch (event.key) {
            case 'Down': // IE/Edge specific value
            case 'ArrowDown':
            case 'Up': // IE/Edge specific value
            case 'ArrowUp':
            case 'Left': // IE/Edge specific value
            case 'ArrowLeft':
            case 'Right': // IE/Edge specific value
            case 'ArrowRight':
            case 'Esc': // IE/Edge specific value
            case 'Escape':
            case 'Shift':
            case 'CapsLock':
            case 'Meta':
                return
        }

        // setValore((a) => {
        //     return [...a, event.key]
        // })

        if (next === true) {
            next = false
            prev = ''
            if (prevBarcode != null) {
                textRef.current!.value! = ''
            }
        }

        console.log(event.key)

        prev += event.key
        if (prev.endsWith(stateSelected.join(''))) {
            callbackValue(prev.replace(stateSelected.join(''), ''))
            if (prevBarcode != null) {
                textRef.current!.value! = prev.replace(stateSelected.join(''), '')
            }
            next = true
        }
        // if (e.key === 'Enter') {
        // }
        event.preventDefault()
    }

    useEffect(() => {
        document.body.addEventListener('keydown', handleKeydown, false)
        return () => {
            document.body.removeEventListener('keydown', handleKeydown)
        }
    }, [])

    const handleOnFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        document.body.removeEventListener('keydown', handleKeydown, false)
    }, [])

    const handleOnBlur = useCallback((event: any) => {
        document.body.removeEventListener('keydown', handleKeydown, false)
        document.body.addEventListener('keydown', handleKeydown, false)
    }, [])

    const handleKeydownInputText = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            callbackValue(textRef.current!.value!.toString())
        }
        return true
    }

    const [isScan, setIsScan] = useState<boolean>(false)

    useEffect(() => {
        if (isScan === true) {
            document.body.removeEventListener('keydown', handleKeydown)
        }
    }, [isScan])

    return (
        <Box>
            {/* <Typography>{valore.map((e) => e.toString())}</Typography> */}
            <Stack direction={'row'}>
                <TextField
                    tabIndex={-1}
                    inputRef={textRef}
                    InputLabelProps={{ shrink: true }}
                    autoComplete="off"
                    fullWidth
                    variant="outlined"
                    //value={dettaglio?.barcode}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    //onChange={handleChange}
                    onKeyDown={handleKeydownInputText}
                    label={title}
                    disabled={isScan}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <IconButton
                                    color="primary"
                                    sx={{ p: '10px' }}
                                    aria-label="directions"
                                    onClick={() => {
                                        setIsScan((is) => !is)
                                    }}
                                >
                                    <DocumentScannerIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    variant="contained"
                    sx={{ ml: 1, p: 2 }}
                    onClick={() => {
                        //setValue('')
                        // navigate(`/ricerca`, {
                        //     state: { type: BARCODE_TYPE.SEARCH, barcode: '' },
                        // })
                        callbackCancel()
                    }}
                >
                    <CancelTwoToneIcon />
                </Button>
                <Button
                    variant="contained"
                    sx={{ ml: 1, p: 2 }}
                    onClick={() => {
                        //setValue('')
                        // navigate(`/ricerca`, {
                        //     state: { type: BARCODE_TYPE.SEARCH, barcode: '' },
                        // })
                        //callbackClear()
                        textRef.current!.value = ''
                    }}
                >
                    <BackspaceTwoToneIcon />
                </Button>
            </Stack>
            <BarcodeVideo
                isScan={isScan}
                cb={(barcode) => {
                    setIsScan(() => false)
                    callbackValue(barcode)
                }}
            />
        </Box>
    )
}
