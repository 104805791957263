import { AddTwoTone, DeleteTwoTone } from '@mui/icons-material'
import {
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Paper,
    Stack,
    styled,
    ThemeOptions,
    Typography,
} from '@mui/material'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectBarcodeSelector, setBarcodeTerminator, deleteBarcodeTerminator } from '../impostazioniSlice'

const Item = styled(Paper)(({ theme }: any) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}))

export function BarcodeTerminator() {
    const stateSelected = useAppSelector(selectBarcodeSelector)

    const dispatch = useAppDispatch()

    const [value, setValue] = useState<string>('')

    return (
        <>
            <Grid mt={3} container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Grid item>
                    <Typography variant="h6">Barcode terminators</Typography>
                </Grid>
            </Grid>
            <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="subtitle1">
                    Carattere postposto al barcode in javascript, p.e. \n = Enter
                </Typography>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password"></InputLabel>
                    <Input
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let input = e.target.value
                            //if (!input || (input[input.length - 1].match('[0-9]') && input[0].match('[1-9]'))) {
                            setValue(input)
                            //}
                        }}
                        placeholder={'Enter'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => {
                                        if (value.length > 0) {
                                            dispatch(setBarcodeTerminator(value))
                                            setValue('')
                                        }
                                    }}
                                >
                                    <AddTwoTone />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <Stack sx={{ m: 2 }} direction="row" spacing={{ xs: 1, sm: 2, md: 4 }}>
                    {stateSelected.map((item: string, index: number) => {
                        return (
                            <Item key={`pos-${index}`}>
                                <>
                                    {item}
                                    <IconButton
                                        onClick={() => {
                                            dispatch(deleteBarcodeTerminator(index))
                                        }}
                                    >
                                        <DeleteTwoTone />
                                    </IconButton>
                                </>
                            </Item>
                        )
                    })}
                </Stack>
            </Paper>
        </>
    )
}
