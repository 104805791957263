import { Grid, Typography } from '@mui/material'
import { BrowserMultiFormatOneDReader, BrowserMultiFormatReader, IScannerControls } from '@zxing/browser'
import { BarcodeFormat, DecodeHintType } from '@zxing/library'
import { useEffect, useRef } from 'react'
import { useAppSelector } from '../../app/hooks'
import { selectCameraDefault } from './barcodeSlice'
import { NO_CAMERA } from './BarcodeVideoSelector'

interface Props {
    cb(item: string): void
    isScan: boolean
}

export function BarcodeVideo({ cb, isScan }: Props) {
    //   https://codesandbox.io/s/scan-barcode-forked-7r265t?file=/src/App.tsx:351-356
    const cameraDefault = useAppSelector(selectCameraDefault)

    const video = useRef<any>()

    const controlsRef = useRef<IScannerControls | null>(null)

    useEffect(() => {
        async function init() {
            const hints = new Map()
            hints.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.CODE_128, BarcodeFormat.CODE_39, BarcodeFormat.QR_CODE])
            hints.set(DecodeHintType.TRY_HARDER, false)
            const codeReader = new BrowserMultiFormatReader(hints)
            // codeReader.scan(video.current.id, function (result, error) {
            //     if (error) {
            //         console.log(error)
            //     } else {
            //         console.log(result)
            //     }
            // })
            let videoConstraints: MediaTrackConstraints;

            videoConstraints = {
                deviceId: { exact: cameraDefault.deviceId },
                width: { min: 640, ideal: 1920 },
                height: { min: 400, ideal: 1080 },
                aspectRatio: { ideal: 1.7777777778 },
                frameRate: {
                    ideal: 60,
                    min: 10
                }
            };

            const constraints: MediaStreamConstraints = { video: videoConstraints };

            controlsRef.current = await codeReader.decodeFromConstraints(
                constraints,
                video.current,
                function (result, error) {
                    if (error) {
                        console.log(error)
                    }
                    if (typeof result !== 'undefined') {
                        controlsRef.current?.stop()
                        video.current = undefined
                        controlsRef.current = null
                        cb(result.getText())
                    }
                }
            )
        }
        if (isScan === true) {
            console.log('ZXing code reader initialized')
            init()
        } else {
            controlsRef.current?.stop()
            video.current = undefined
            controlsRef.current = null
        }

        return function cleanup() {
            console.log('clean')
            controlsRef.current?.stop()
        }
    }, [isScan])

    return (
        <>
            {isScan === true && (
                <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ mt: 1 }}>
                    {cameraDefault.deviceId != NO_CAMERA.deviceId ? (
                        <video style={{ width: '100%' }} ref={video} muted></video>
                    ) : (
                        <Typography variant="h6">{NO_CAMERA.label}</Typography>
                    )}
                </Grid>
            )}
        </>
    )
}
