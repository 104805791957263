import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { barcodeApi } from '../features/barcode/barcodeAPI'
import barcodeReducer from '../features/barcode/barcodeSlice'
import { immaginiApi } from '../features/immagini/immaginiAPI'
import immaginiReducer from '../features/immagini/immaginiSlice'
import { loginApi } from '../features/login/loginAPI'
import loginReducer from '../features/login/loginSlice'
import colliConfezReducer from '../features/colliConfez/colliConfezSlice'
import colliConfezRigheReducer from '../features/colliConfezRighe/colliConfezRigheSlice'
import { impostazioniApi } from '../features/impostazioni/impostazioniAPI'
import impostazioniReducer from '../features/impostazioni/impostazioniSlice'
import { stampeApi } from '../features/stampe/stampeAPI'
import stampeReducer from '../features/stampe/stampeSlice'
import { clientiTemplateApi } from '../features/clientiTemplate/clientiTemplateAPI'
import clientiTemplateReducer from '../features/clientiTemplate/clientiTemplateSlice'
import { rtkQueryErrorLogger } from './errorCatching'
import { api } from './api'

export const store = configureStore({
    reducer: {
        barcode: barcodeReducer,
        login: loginReducer,
        immagini: immaginiReducer,
        colliConfez: colliConfezReducer,
        colliConfezRighe: colliConfezRigheReducer,
        impostazioni: impostazioniReducer,
        stampe: stampeReducer,
        clientiTemplate: clientiTemplateReducer,
        [loginApi.reducerPath]: loginApi.reducer,
        [barcodeApi.reducerPath]: barcodeApi.reducer,
        [immaginiApi.reducerPath]: immaginiApi.reducer,
        [api.reducerPath]: api.reducer,
        [impostazioniApi.reducerPath]: impostazioniApi.reducer,
        [stampeApi.reducerPath]: stampeApi.reducer,
        [clientiTemplateApi.reducerPath]: clientiTemplateApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            rtkQueryErrorLogger,
            loginApi.middleware,
            barcodeApi.middleware,
            immaginiApi.middleware,
            api.middleware,
            impostazioniApi.middleware,
            stampeApi.middleware,
            clientiTemplateApi.middleware
        ),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
