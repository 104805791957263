import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { ColliConfezObj, ColliConfezObjBarcode } from '../colliConfez/colliConfezSlice'
import { TipiCodArt } from '../impostazioni/impostazioniSlice'

const STORAGE_KEY_TEMPLATE_1 = 'TEMPLATE_1'
const STORAGE_KEY_TEMPLATE_2 = 'TEMPLATE_2'
const STORAGE_KEY_TEMPLATE_3 = 'TEMPLATE_3'
const STORAGE_KEY_TEMPLATE_4 = 'TEMPLATE_4'

export enum TEMPLATE_ID {
    TEMPLATE_0 = 0,
    TEMPLATE_1 = 1,
    TEMPLATE_2 = 2,
    TEMPLATE_3 = 3,
    TEMPLATE_4 = 4,
}
export interface Printer {
    templateId: TEMPLATE_ID
    isDefault: boolean
    name: string
    options: any
    status: string
}

export interface PrinterResponse {
    data: Array<Printer> | []
    message?: string | null
}

interface Printers {
    template1: Printer
    template2: Printer
    template3: Printer
    template4: Printer
}

const initialState: Printers = {
    template1: JSON.parse(window.localStorage.getItem(STORAGE_KEY_TEMPLATE_1) || '{}') || null,
    template2: JSON.parse(window.localStorage.getItem(STORAGE_KEY_TEMPLATE_2) || '{}') || null,
    template3: JSON.parse(window.localStorage.getItem(STORAGE_KEY_TEMPLATE_3) || '{}') || null,
    template4: JSON.parse(window.localStorage.getItem(STORAGE_KEY_TEMPLATE_4) || '{}') || null,
}

export interface StampaObj {
    template?: Printer | null
    colliConfez: ColliConfezObj
    tipiCodArtInterno: TipiCodArt
    isPreview: boolean
}

// // The function below is called a thunk and allows us to perform async logic. It
// // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// // will call the thunk with the `dispatch` function as the first argument. Async
// // code can then be executed and other actions can be dispatched. Thunks are
// // typically used to make async requests.
// export const incrementAsync = createAsyncThunk('login/fetchCount', async (amount: number) => {
//     const response = await fetchCount(amount)
//     // The value we return becomes the `fulfilled` action payload
//     return response.data
// })

export const stampeSlice = createSlice({
    name: 'stampe',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setTemplate: (state, action: PayloadAction<Printer>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            switch (action.payload.templateId) {
                case TEMPLATE_ID.TEMPLATE_1:
                    state.template1 = action.payload
                    window.localStorage.setItem(STORAGE_KEY_TEMPLATE_1, JSON.stringify(action.payload))
                    break
                case TEMPLATE_ID.TEMPLATE_2:
                    state.template2 = action.payload
                    window.localStorage.setItem(STORAGE_KEY_TEMPLATE_2, JSON.stringify(action.payload))
                    break
                case TEMPLATE_ID.TEMPLATE_3:
                    state.template3 = action.payload
                    window.localStorage.setItem(STORAGE_KEY_TEMPLATE_3, JSON.stringify(action.payload))
                    break
                case TEMPLATE_ID.TEMPLATE_4:
                    state.template4 = action.payload
                    window.localStorage.setItem(STORAGE_KEY_TEMPLATE_4, JSON.stringify(action.payload))
                    break
            }
        },
        // decrement: (state) => {
        //     state.value -= 1
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload
        // },
    },
    // extraReducers: (builder) => {
    //     builder.addMatcher(valoriApi.endpoints.post.matchFulfilled, (state, { payload, type, meta }) => {
    //         console.log(payload)

    //         state[meta.arg.originalArgs.type] = payload
    //     })
    // },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(incrementAsync.pending, (state) => {
    //             state.status = 'loading'
    //         })
    //         .addCase(incrementAsync.fulfilled, (state, action) => {
    //             state.status = 'idle'
    //             state.value += action.payload
    //         })
    //         .addCase(incrementAsync.rejected, (state) => {
    //             state.status = 'failed'
    //         })
    // },
})

export const { setTemplate } = stampeSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.login.value)`
export const selectState = (state: RootState) => state.stampe

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const setAziendaStored =
//     (azienda: AziendaObj): AppThunk =>
//     (dispatch, getState) => {
//         const aziendaSelected = selectAzienda(getState())
//         console.log(aziendaSelected)
//         dispatch(setAzienda(azienda))
//     }

export default stampeSlice.reducer
