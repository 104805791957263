import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { immaginiApi } from './immaginiAPI'

const STORAGE_KEY_DEFAULT_CAMERA_IMMAGINI_SELECTED = 'DEFAULT_CAMERA_IMMAGINI_SELECTED'

export enum TIPO_IMAGE {
    COLLI_CONFEZ = 'COLLI_CONFEZ',
    COLLI_CONFEZ_RIGHE = 'COLLI_CONFEZ_RIGHE',
}

export interface ImmagineRequest {
    TIPO: TIPO_IMAGE
    id: string
    HASH?: string

    AZIENDA_ID?: number
    COD_TIPO_IMAGE_DOC?: string
    DOC_ID?: string

    // COLLI_CONFEZ_RIGHE
    DOC_RIGA_ID?: string

    PROGR?: number

    // COLLI_CONFEZ
    DOC_PROGR_ID?: string

    // COLLI_CONFEZ_RIGHE
    DOC_RIGA_PROGR_ID?: string

    IMMAGINE?: any
    FLAG_OLE?: number
    IMAGE_WIDTH?: number
    IMAGE_HEIGHT?: number
    NOTE?: string
}

export interface ImmaginiResponse {
    images: Array<ImmagineRequest>
    message?: string | null
}

export interface ImmaginiResponseInsert {
    image: ImmagineRequest
    message?: string | null
}

export interface ImmaginiResponseDelete {
    image: ImmagineRequest
    message?: string | null
}

export interface State {
    cameraDefault: MediaDeviceInfo
    immaginiResponse: ImmaginiResponse
}

const initialState: State = {
    cameraDefault: JSON.parse(window.localStorage.getItem(STORAGE_KEY_DEFAULT_CAMERA_IMMAGINI_SELECTED) || '{}') || null,
    immaginiResponse: {
        images: [],
    },
}

// // The function below is called a thunk and allows us to perform async logic. It
// // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// // will call the thunk with the `dispatch` function as the first argument. Async
// // code can then be executed and other actions can be dispatched. Thunks are
// // typically used to make async requests.
// export const incrementAsync = createAsyncThunk('login/fetchCount', async (amount: number) => {
//     const response = await fetchCount(amount)
//     // The value we return becomes the `fulfilled` action payload
//     return response.data
// })

export const immaginiSlice = createSlice({
    name: 'immagini',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        addImage: (state, action: PayloadAction<ImmagineRequest>) => {
            state.immaginiResponse.images = [action.payload, ...state.immaginiResponse.images]
        },
        resetImage: (state, action: PayloadAction<void>) => {
            state.immaginiResponse.images = []
        },
        setCameraDefault: (state, action: PayloadAction<any>) => {
            state.cameraDefault = action.payload
            window.localStorage.setItem(STORAGE_KEY_DEFAULT_CAMERA_IMMAGINI_SELECTED, JSON.stringify(action.payload))
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(immaginiApi.endpoints.immagini.matchFulfilled, (state, { payload }) => {
                state.immaginiResponse.images = payload.images
            })
            .addMatcher(immaginiApi.endpoints.insertPost.matchFulfilled, (state, { payload }) => {
                state.immaginiResponse.images.push(payload.image)
            })
            .addMatcher(immaginiApi.endpoints.deletePost.matchFulfilled, (state, { payload }) => {
                state.immaginiResponse.images = state.immaginiResponse.images.filter((it) => it.id !== payload.image.id)
            })
    },
})

export const { addImage, resetImage, setCameraDefault } = immaginiSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.login.value)`
export const selectImages = (state: RootState) => state.immagini.immaginiResponse.images
export const selectCameraImmaginiDefault = (state: RootState) => state.immagini.cameraDefault

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//     (amount: number): AppThunk =>
//     (dispatch, getState) => {
//         const currentValue = selectCount(getState())
//         if (currentValue % 2 === 1) {
//             dispatch(incrementByAmount(amount))
//         }
//     }

export default immaginiSlice.reducer
