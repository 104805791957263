import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material'
import 'moment/locale/it'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { snackActions } from '../../SnackbarUtilsConfigurator'
import { BarcodeVideoSelectDefault } from '../barcode/BarcodeVideoSelector'
import { FILTRO_TYPE, SITUAZIONE_TYPE } from '../colliConfez/colliConfezSlice'
import { ComboBoxFiltro } from '../colliConfez/ComboBoxFiltro'
import { ComboBoxSituazione } from '../colliConfez/ComboBoxSituazione'
import { TIPO_IMAGE } from '../immagini/immaginiSlice'
import { ImmaginiVideoSelectDefault } from '../immagini/ImmaginiVideoSelector'
import { StampeImpostazioni } from '../stampe/StampeImpostazioni'
import { BarcodeTerminator } from './barcodeTerminator/BarcodeTerminator'
import {
    useGetAziendeQuery,
    useGetImballiQuery,
    useGetTipiCodArtQuery,
    useGetTipiImageDocQuery,
} from './impostazioniAPI'
import {
    AziendaObj,
    ImballoObj,
    selectAzienda,
    selectFiltroOrdiniAnonimoDefault,
    selectImballo,
    selectSituazioneColliDefault,
    selectTipiCodArtInterno,
    selectTipiImageDocColliConfez,
    selectTipiImageDocColliConfezRighe,
    setAzienda,
    setFiltroOrdiniAnonimo,
    setImballo,
    setSituazioneColli,
    setTipiCodArtInterno,
    setTipiImageDocColliConfez,
    setTipiImageDocColliConfezRighe,
    TipiCodArt,
    TipiImageDoc,
} from './impostazioniSlice'

interface ImpostazioniProps {
    gridColor?: string
}

export function Impostazioni({ gridColor }: ImpostazioniProps) {
    const dispatch = useAppDispatch()

    const { currentData: dataAz } = useGetAziendeQuery()

    const { currentData: dataIm } = useGetImballiQuery()

    const { currentData: dataTipiImageDocColliConfez } = useGetTipiImageDocQuery({
        tipoImage: TIPO_IMAGE.COLLI_CONFEZ,
    })
    const { currentData: dataTipiImageDocColliConfezRighe } = useGetTipiImageDocQuery({
        tipoImage: TIPO_IMAGE.COLLI_CONFEZ_RIGHE,
    })

    const { currentData: dataTipiCodArt } = useGetTipiCodArtQuery()

    const aziendaSelected = useAppSelector(selectAzienda)
    const imballoSelected = useAppSelector(selectImballo)
    const tipiImageDocColliConfez = useAppSelector(selectTipiImageDocColliConfez)
    const tipiImageDocColliConfezRighe = useAppSelector(selectTipiImageDocColliConfezRighe)
    const tipiCodArtInterno = useAppSelector(selectTipiCodArtInterno)

    const situazioneColli = useAppSelector(selectSituazioneColliDefault)
    const filtroOrdiniAnonimo = useAppSelector(selectFiltroOrdiniAnonimoDefault)

    const handleChangeAzienda = (event: SelectChangeEvent) => {
        const azSelected = dataAz?.data.find((a: AziendaObj) => a.AZIENDA_ID?.toString() === event.target.value)
        dispatch(setAzienda(azSelected!))
    }

    const handleChangeImballo = (event: SelectChangeEvent) => {
        const imSelected = dataIm?.data.find((a: ImballoObj) => a.COD_IMB?.toString() === event.target.value)
        dispatch(setImballo(imSelected!))
    }

    const handleChangeTipiImageDocColliConfez = (event: SelectChangeEvent) => {
        const ccSelected = dataTipiImageDocColliConfez?.data.find(
            (a: TipiImageDoc) => a.COD_TIPO_IMAGE_DOC?.toString() === event.target.value
        )
        dispatch(setTipiImageDocColliConfez(ccSelected!))
    }

    const handleChangeTipiImageDocColliConfezRighe = (event: SelectChangeEvent) => {
        const ccrSelected = dataTipiImageDocColliConfezRighe?.data.find(
            (a: TipiImageDoc) => a.COD_TIPO_IMAGE_DOC?.toString() === event.target.value
        )
        dispatch(setTipiImageDocColliConfezRighe(ccrSelected!))
    }

    const handleChangeCodiceInterno = (event: SelectChangeEvent) => {
        const ciSelected = dataTipiCodArt?.data.find(
            (a: TipiCodArt) => a.COD_TIPO_COD?.toString() === event.target.value
        )
        dispatch(setTipiCodArtInterno(ciSelected!))
    }

    return (
        <>
            <Grid mt={3} container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Grid item>
                    <Typography variant="h6">Parametri HyperGest</Typography>
                </Grid>
            </Grid>
            <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="subtitle1">Generale</Typography>
                <Box sx={{ minWidth: 120, p: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel id="label-azienda">Aziende</InputLabel>
                        <Select
                            labelId="label-azienda"
                            id="azienda-id"
                            value={String(aziendaSelected.AZIENDA_ID == null ? '' : aziendaSelected.AZIENDA_ID)}
                            label="Aziende"
                            onChange={handleChangeAzienda}
                        >
                            {dataAz?.data.map((item: AziendaObj) => {
                                return (
                                    <MenuItem key={item.AZIENDA_ID} value={item.AZIENDA_ID}>
                                        {item.AZIENDA_ID} - {item.RAGIONE_SOCIALE}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="label-imballo">Imballo</InputLabel>
                        <Select
                            labelId="label-imballo"
                            id="imballo-id"
                            value={String(imballoSelected.COD_IMB == null ? '' : imballoSelected.COD_IMB)}
                            label="Imballo"
                            onChange={handleChangeImballo}
                        >
                            {dataIm?.data.map((item: ImballoObj) => {
                                return (
                                    <MenuItem key={item.COD_IMB} value={item.COD_IMB}>
                                        {item.COD_IMB} - {item.DES_IMB}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Typography variant="subtitle1">Immagini</Typography>
                <Box sx={{ minWidth: 120, p: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel id="label-cc">Tipi colli confezioni</InputLabel>
                        <Select
                            labelId="label-cc"
                            id="cc-id"
                            value={String(
                                tipiImageDocColliConfez.COD_TIPO_IMAGE_DOC == null
                                    ? ''
                                    : tipiImageDocColliConfez.COD_TIPO_IMAGE_DOC
                            )}
                            label="Tipo colli confezioni"
                            onChange={handleChangeTipiImageDocColliConfez}
                        >
                            {dataTipiImageDocColliConfez?.data.map((item: TipiImageDoc) => {
                                return (
                                    <MenuItem key={item.COD_TIPO_IMAGE_DOC} value={item.COD_TIPO_IMAGE_DOC}>
                                        {item.COD_TIPO_IMAGE_DOC} - {item.DES_TIPO_IMAGE_DOC}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="label-ccr">Tipi colli confezioni righe</InputLabel>
                        <Select
                            labelId="label-ccr"
                            id="ccr-id"
                            value={String(
                                tipiImageDocColliConfezRighe.COD_TIPO_IMAGE_DOC == null
                                    ? ''
                                    : tipiImageDocColliConfezRighe.COD_TIPO_IMAGE_DOC
                            )}
                            label="Tipi colli confezioni righe"
                            onChange={handleChangeTipiImageDocColliConfezRighe}
                        >
                            {dataTipiImageDocColliConfezRighe?.data.map((item: TipiImageDoc) => {
                                return (
                                    <MenuItem key={item.COD_TIPO_IMAGE_DOC} value={item.COD_TIPO_IMAGE_DOC}>
                                        {item.COD_TIPO_IMAGE_DOC} - {item.DES_TIPO_IMAGE_DOC}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>

                <Typography variant="subtitle1">
                    Tipi codice articoli interni (scelta codice secondario interno per stampa elenco etichette articoli)
                </Typography>
                <Box sx={{ minWidth: 120, p: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel id="label-ci">Codice interno</InputLabel>
                        <Select
                            labelId="label-ci"
                            id="ci-id"
                            value={String(tipiCodArtInterno.COD_TIPO_COD == null ? '' : tipiCodArtInterno.COD_TIPO_COD)}
                            label="Codice interno"
                            onChange={handleChangeCodiceInterno}
                        >
                            {dataTipiCodArt?.data.map((item: TipiCodArt) => {
                                return (
                                    <MenuItem key={item.COD_TIPO_COD} value={item.COD_TIPO_COD}>
                                        {item.COD_TIPO_COD} - {item.DES_TIPO_COD}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
            </Paper>

            <Grid mt={3} container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Grid item>
                    <Typography variant="h6">Fotocamere</Typography>
                </Grid>
            </Grid>
            <Paper elevation={3} sx={{ p: 2 }}>
                <Box sx={{ minWidth: 120, p: 2 }}>
                    <Typography variant="subtitle1">
                        Seleziona la fotocamera di default per scansionare i BARCODE di questo device
                    </Typography>
                    <BarcodeVideoSelectDefault
                        cb={(item: string): void => {
                            snackActions.info(item)
                        }}
                    />
                    <Typography sx={{ pt: 2 }} variant="subtitle1">
                        Seleziona la fotocamera di default per l'acquisizione delle foto
                    </Typography>
                    <ImmaginiVideoSelectDefault
                        cb={(item: string): void => {
                            snackActions.info(item)
                        }}
                    />
                </Box>
            </Paper>

            <Grid mt={3} container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Grid item>
                    <Typography variant="h6">Parametri di lavoro di default</Typography>
                </Grid>
            </Grid>
            <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="caption">
                    Il filtro "Ordini e Anonimi" o "Tutti" mostreranno i pulsanti di inserimento disabilitati (le
                    restati modalità, invece, utilizzano la selezione del "Filtro" o della "Situazione colli" come
                    valore per l'inserimento)
                </Typography>
                <Box sx={{ minWidth: 120, p: 2 }}>
                    <ComboBoxFiltro
                        filtro={filtroOrdiniAnonimo as FILTRO_TYPE}
                        callback={(value: FILTRO_TYPE) => {
                            dispatch(setFiltroOrdiniAnonimo(value))
                        }}
                    />
                </Box>
                <Box sx={{ minWidth: 120, p: 2 }}>
                    <ComboBoxSituazione
                        situazione={situazioneColli as SITUAZIONE_TYPE}
                        callback={(value: SITUAZIONE_TYPE) => {
                            dispatch(setSituazioneColli(value))
                        }}
                    />
                </Box>
            </Paper>

            <BarcodeTerminator />
            <StampeImpostazioni />
        </>
    )
}
