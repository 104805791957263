import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { loginApi } from './loginAPI'

const STORAGE_KEY = 'USER'

export interface LoginRequest {
    password: string
    email: string
}

export interface LoginResponse {
    data?: {
        id: string
        password: string
        email: string
        UTENTE: string
    } | null
    message?: string | null
}

const initialState: LoginResponse = JSON.parse(window.localStorage.getItem(STORAGE_KEY) || '{}') || null

// // The function below is called a thunk and allows us to perform async logic. It
// // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// // will call the thunk with the `dispatch` function as the first argument. Async
// // code can then be executed and other actions can be dispatched. Thunks are
// // typically used to make async requests.
// export const incrementAsync = createAsyncThunk('login/fetchCount', async (amount: number) => {
//     const response = await fetchCount(amount)
//     // The value we return becomes the `fulfilled` action payload
//     return response.data
// })

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1
        // },
        // decrement: (state) => {
        //     state.value -= 1
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload
        // },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(loginApi.endpoints.loginPost.matchFulfilled, (state, { payload }) => {
                state.data = payload.data
                state.message = payload.message
                window.localStorage.setItem(STORAGE_KEY, JSON.stringify(payload))
            })
            .addMatcher(loginApi.endpoints.logoutPost.matchFulfilled, (state, { payload }) => {
                delete state.data
                delete state.message
                window.localStorage.removeItem(STORAGE_KEY)
                //document.cookie = ''
            })
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(incrementAsync.pending, (state) => {
    //             state.status = 'loading'
    //         })
    //         .addCase(incrementAsync.fulfilled, (state, action) => {
    //             state.status = 'idle'
    //             state.value += action.payload
    //         })
    //         .addCase(incrementAsync.rejected, (state) => {
    //             state.status = 'failed'
    //         })
    // },
})

export const {} = loginSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.login.value)`

export const selectUser = (state: RootState) => state.login.data

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//     (amount: number): AppThunk =>
//     (dispatch, getState) => {
//         const currentValue = selectCount(getState())
//         if (currentValue % 2 === 1) {
//             dispatch(incrementByAmount(amount))
//         }
//     }

export default loginSlice.reducer
